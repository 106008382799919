<script>
  import PlanInput from "./../components/DelInputScreen/PlanInput.svelte";
  import DelDatePicker from "./../components/DelDatePicker/DelDatePicker.svelte";
  import {
    rootNode,
    parentNodeType,
    plantNodeType,
    startTimeOffSet,
    currentPage,
    availableScreens,
  } from "./../stores";
  import { screensToShow } from "./../../config.js";
  import { getDomainURL } from "./../invokeAPI";
  import moment from "moment";
  import { onMount } from "svelte";

  onMount(() => {
    sessionStorage.removeItem("application-initial-load");
  });

  let selectedNodeId = null;
  let selectedDatePlanInput = null;
  const fututeMaxDate = moment().add(5000, "day").valueOf();
  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];
  let defaultStartDatePlanInput = sessionStorage.getItem(
    "plan-input-saved-start-date"
  )
    ? sessionStorage.getItem("plan-input-saved-start-date")
    : new Date(moment().subtract(6, "day").startOf("day"));
  let defaultEndDatePlanInput = sessionStorage.getItem(
    "plan-input-saved-end-date"
  )
    ? sessionStorage.getItem("plan-input-saved-end-date")
    : new Date(moment().endOf("day"));
  let dateFromSessionStorage =
    sessionStorage.getItem("plan-input-saved-start-date") ?? null;

  const checkIfTodayHasStarted = function (offSet, endTime) {
    const currentTime = moment().valueOf();
    if (!offSet) {
      const offSetFromLocalStorage = endTime
        ? localStorage.getItem("endTimeOffset")
        : localStorage.getItem("startTimeOffset");
      offSet = offSetFromLocalStorage ? parseInt(offSetFromLocalStorage) : 0;
    }
    return moment().startOf("day").valueOf() + offSet <= currentTime;
  };
  if (!checkIfTodayHasStarted($startTimeOffSet)) {
    if (!dateFromSessionStorage) {
      defaultStartDatePlanInput = new Date(
        moment(defaultStartDatePlanInput).subtract(1, "day")
      );
      defaultEndDatePlanInput = new Date(
        moment(defaultEndDatePlanInput).subtract(1, "day")
      );
    }
  }

  const onDateChange = function (newDate) {
    if (newDate) {
      selectedDatePlanInput = { ...newDate };
      sessionStorage.setItem("plan-input-saved-start-date", newDate?.start);
      sessionStorage.setItem("plan-input-saved-end-date", newDate?.end);
    }
  };

  const setSelectedNodeId = function () {
    if ($rootNode?.type === $parentNodeType && $rootNode?.children?.length) {
      selectedNodeId = $rootNode?.children[0].id;
    } else {
      selectedNodeId = $rootNode.id;
    }
    console.log("selected node id", selectedNodeId);
  };

  const deviceCategory = "Enterprise";
  const parameterCategories = ["daily_Sp_cons_FG", "daily_Sp_cons_Tanks"];

  const parametersToDisable = [];

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);

  $: {
    if ($parentNodeType && $rootNode && $plantNodeType) {
      if (
        $rootNode.type === $parentNodeType ||
        $rootNode?.type === $plantNodeType
      ) {
        setSelectedNodeId();
      }
    }
  }
</script>

<div class="del-page-container del-plan-input-page-container">
  <div class="del-plan-input-date-picker">
    <DelDatePicker
      customRangesToShow={dateRangesToShow}
      onDateChanged={onDateChange}
      autoApply={true}
      defaultStartDate={defaultStartDatePlanInput}
      defaultEndDate={defaultEndDatePlanInput}
      zIndex={11}
      firstDay={1}
      maxDate={fututeMaxDate}
    ></DelDatePicker>
  </div>
  <PlanInput
    parentNodeID={selectedNodeId}
    baseURL={getDomainURL()}
    currentScreen={"Plant Input"}
    startTime={selectedDatePlanInput?.dayDefinedStartDateInSeconds}
    endTime={selectedDatePlanInput?.dayDefinedEndDateInSeconds}
    offsetTime={$startTimeOffSet}
    {deviceCategory}
    parameterCategory={parameterCategories}
    isWritePermission={screenPermission === "RW"}
    {parametersToDisable}
  ></PlanInput>
</div>

<style>
  .del-plan-input-page-container {
    padding: 20px;
  }

  .del-plan-input-date-picker {
    padding-bottom: 10px;
  }
</style>
