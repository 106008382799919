<script>
  import {
    infoBoardSelectedTimePeriod,
    selectedNode,
    screenWidth,
  } from "./../stores";
  import moment from "moment";
  import DelConsumptionTrendChart from "./../components/DelConsumptionTrendChart/DelConsumptionTrendChart.svelte";
  import DelTrendCard from "./../components/DelTrendCard/DelTrendCard.svelte";
  import DelDonutChart from "./../components/DelDonutChart/DelDonutChart.svelte";
  import DelCumulativeChart from "./../components/DelCumulativeChart/DelCumulativeChart.svelte";
  import { getDomainURL, invokeAPI } from "./../invokeAPI";
  import {
    infoBoardIntervalDuration,
    infoboardEnergyConsumptionDetails,
    infoboardDonutChartConfig,
    infoboardCarbonFootPrintConfig,
    consumptionTrendChartConfig,
    cumulativeGraphConfig,
  } from "./../../config";

  import {
    checkForNoData,
    filterParameterId,
    addOffSet,
    epoch13to10,
  } from "./../utilityFunctions";
  import { onMount, onDestroy } from "svelte";

  let timePeriodUpdateInterval = null;
  let timePeriodUpdateOrder = ["ytd", "mtd", "wtd"];
  let infoBoardSettings = {};
  let rootElement = null;

  let totalCost = "";
  let isTotalCostValid = false;

  $: {
    if ($selectedNode) getTotalCost();
  }

  const openInFullScreen = function () {
    try {
      if (rootElement?.requestFullscreen) {
        rootElement?.requestFullscreen();
      } else if (rootElement?.webkitRequestFullscreen) {
        /* Safari */
        rootElement?.webkitRequestFullscreen();
      } else if (rootElement?.msRequestFullscreen) {
        /* IE11 */
        rootElement?.msRequestFullscreen();
      }
    } catch (error) {
      console.error("Unable to open in full screen", error);
    }
  };

  const closeFullscreen = function () {
    console.log(
      "closing full screen",
      rootElement,
      rootElement?.exitFullscreen
    );
    try {
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else if (
        document.webkitExitFullscreen &&
        document.webkitFullscreenElement
      ) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen && document.mozFullScreenElement) {
        /* IE11 */
        document.msExitFullscreen();
      }
    } catch (error) {
      console.error("Unable to close fullscreen");
    }
  };

  onMount(() => {
    rootElement = document.getElementById("del-application-container");
    const isFirstLoad = sessionStorage.getItem("application-initial-load");
    if (!isFirstLoad) {
      openInFullScreen();
    } else {
      sessionStorage.removeItem("application-initial-load");
    }
  });

  onDestroy(() => {
    if (timePeriodUpdateInterval) {
      clearInterval(timePeriodUpdateInterval);
      timePeriodUpdateInterval = null;
    }
    closeFullscreen();
  });

  const updateTimePeriods = function () {
    const currentIndex = timePeriodUpdateOrder.indexOf(
      infoBoardSettings.currentInterval
    );
    const newIndex = (currentIndex + 1) % timePeriodUpdateOrder.length;
    setTimePeriods(timePeriodUpdateOrder[newIndex]);
  };

  const setTimePeriods = function (timePeriod) {
    switch (timePeriod) {
      case "ytd":
        setYTDDetails();
        break;
      case "mtd":
        setMTDDetails();
        break;
      case "wtd":
        setWTDDetails();
        break;
      default:
        break;
    }
    getTotalCost();
  };

  const setYTDDetails = function () {
    let _infoBoardSettings = { ...infoBoardSettings };
    _infoBoardSettings.currentInterval = "ytd";
    _infoBoardSettings.groupBy = "month";
    _infoBoardSettings.xAxisLabelFormat = "MMM";
    _infoBoardSettings.xAxisLabelHoverFormat = "MMM YYYY";
    _infoBoardSettings.currentStartTime = epoch13to10(
      addOffSet(parseInt(moment().startOf("year").format("X")) * 1000)
    );
    _infoBoardSettings.currentEndTime = epoch13to10(
      addOffSet((parseInt(moment().endOf("year").format("X")) + 1) * 1000)
    );
    _infoBoardSettings.previousStartTime = epoch13to10(
      addOffSet(
        parseInt(moment().subtract(1, "year").startOf("year").format("X")) *
          1000
      )
    );
    _infoBoardSettings.previousEndTime = epoch13to10(
      addOffSet(
        (parseInt(moment().subtract(1, "year").endOf("year").format("X")) + 1) *
          1000
      )
    );
    _infoBoardSettings.currentEndTimeWRTNow = parseInt(moment().format("X"));
    _infoBoardSettings.previousEndTimeWRTNow = parseInt(
      moment().subtract(1, "year").format("X")
    );
    infoBoardSettings = { ..._infoBoardSettings };
  };

  const setMTDDetails = function () {
    let _infoBoardSettings = { ...infoBoardSettings };
    _infoBoardSettings.currentInterval = "mtd";
    _infoBoardSettings.groupBy = "day";
    _infoBoardSettings.xAxisLabelFormat = "DD";
    _infoBoardSettings.xAxisLabelHoverFormat = "DD MMM YYYY";
    _infoBoardSettings.currentStartTime = epoch13to10(
      addOffSet(parseInt(moment().startOf("month").format("X")) * 1000)
    );
    _infoBoardSettings.currentEndTime = epoch13to10(
      addOffSet((parseInt(moment().endOf("month").format("X")) + 1) * 1000)
    );
    _infoBoardSettings.previousStartTime = epoch13to10(
      addOffSet(
        parseInt(moment().subtract(1, "month").startOf("month").format("X")) *
          1000
      )
    );
    _infoBoardSettings.previousEndTime = epoch13to10(
      addOffSet(
        (parseInt(moment().subtract(1, "month").endOf("month").format("X")) +
          1) *
          1000
      )
    );
    _infoBoardSettings.currentEndTimeWRTNow = parseInt(moment().format("X"));
    _infoBoardSettings.previousEndTimeWRTNow = parseInt(
      moment().subtract(1, "month").format("X")
    );
    infoBoardSettings = { ..._infoBoardSettings };
  };

  const setWTDDetails = function () {
    let _infoBoardSettings = { ...infoBoardSettings };
    _infoBoardSettings.currentInterval = "wtd";
    _infoBoardSettings.groupBy = "day";
    _infoBoardSettings.xAxisLabelFormat = "ddd";
    _infoBoardSettings.xAxisLabelHoverFormat = "DD MMM YYYY";
    _infoBoardSettings.currentStartTime = epoch13to10(
      addOffSet(parseInt(moment().startOf("isoWeek").format("X")) * 1000)
    );
    _infoBoardSettings.currentEndTime = epoch13to10(
      addOffSet((parseInt(moment().endOf("isoWeek").format("X")) + 1) * 1000)
    );
    _infoBoardSettings.previousStartTime = epoch13to10(
      addOffSet(
        parseInt(moment().subtract(1, "week").startOf("isoWeek").format("X")) *
          1000
      )
    );
    _infoBoardSettings.previousEndTime = epoch13to10(
      addOffSet(
        (parseInt(moment().subtract(1, "week").endOf("isoWeek").format("X")) +
          1) *
          1000
      )
    );
    _infoBoardSettings.currentEndTimeWRTNow = parseInt(moment().format("X"));
    _infoBoardSettings.previousEndTimeWRTNow = parseInt(
      moment().subtract(1, "week").format("X")
    );
    infoBoardSettings = { ..._infoBoardSettings };
  };

  const setIntervalForAuto = function () {
    timePeriodUpdateInterval = setInterval(
      updateTimePeriods,
      infoBoardIntervalDuration
    );
  };

  const onTimePeriodChange = function (timePeriod) {
    if (timePeriodUpdateInterval) {
      clearInterval(timePeriodUpdateInterval);
      timePeriodUpdateInterval = null;
    }
    sessionStorage.setItem("del-infoboard-selected-timeperiod", timePeriod);
    if (timePeriod === "auto") {
      setIntervalForAuto();
      setTimePeriods("ytd");
    } else {
      setTimePeriods(timePeriod);
    }
    openInFullScreen();
  };

  const getTotalCost = function () {
    isTotalCostValid = false;
    const parameterCategories =
      infoboardEnergyConsumptionDetails.costParameterCategories;
    const deviceCategories =
      infoboardEnergyConsumptionDetails.costDeviceCategories;
    const parameterType = infoboardEnergyConsumptionDetails.costParameterType;
    const parameterId = filterParameterId(
      $selectedNode,
      parameterCategories,
      deviceCategories,
      parameterType
    );

    if (
      parameterId &&
      infoBoardSettings?.currentStartTime &&
      infoBoardSettings?.currentEndTime
    ) {
      const payload = {
        endTime: infoBoardSettings.currentEndTime,
        startTime: infoBoardSettings.currentStartTime,
        parameters: [parameterId],
      };
      totalCost = "Loading...";
      invokeCostAPI(payload);
    }
  };

  const costAPIOnSuccess = function (data) {
    if (data?.length) {
      const result = checkForNoData(data[0].Result);
      if (result) {
        totalCost = `${infoboardEnergyConsumptionDetails.costUnit} ${Math.round(
          result
        ).toLocaleString(infoboardEnergyConsumptionDetails.costFormat)}`;
        isTotalCostValid = true;
      } else {
        totalCost = "No Data";
      }
    } else {
      totalCost = "No Data";
    }
  };

  const costAPIOnError = function () {
    totalCost = "No Data";
  };

  const invokeCostAPI = function (payload) {
    const endpoint = "dpe/api/evaluator";
    invokeAPI(
      "POST",
      endpoint,
      costAPIOnSuccess,
      costAPIOnError,
      payload,
      "Dashboard/R"
    );
  };

  $: {
    onTimePeriodChange($infoBoardSelectedTimePeriod);
  }
</script>

<div class="del-infoboard-page-container del-page-container">
  <div class="del-left-container">
    <div class="del-energy-consumption-container del-container">
      <div class="energy-consumption-container-title-container">
        {`${infoboardEnergyConsumptionDetails.mainTitle} (${
          infoBoardSettings?.currentInterval?.toUpperCase() ?? ""
        })`}
      </div>
      <div class="energy-consumption-inner-container">
        <div class="energy-consumption-readings-container">
          <div class="total-consumption-trend-card-container">
            <DelTrendCard
              baseURL={getDomainURL()}
              currentScreen={"Infoboard"}
              nodeDetails={$selectedNode}
              parameterCategory={infoboardEnergyConsumptionDetails.totalParameterCategories}
              deviceCategory={infoboardEnergyConsumptionDetails.totalDeviceCategories}
              parameterType={infoboardEnergyConsumptionDetails.totalParameterType}
              currStartTime={infoBoardSettings.currentStartTime}
              currEndTime={infoBoardSettings.currentEndTimeWRTNow}
              prevStartTime={infoBoardSettings.previousStartTime}
              prevEndTime={infoBoardSettings.previousEndTimeWRTNow}
              widgetTitle={infoboardEnergyConsumptionDetails.totalTrendCardTitle}
              trendType={infoboardEnergyConsumptionDetails.totalTrendType}
              unit={infoboardEnergyConsumptionDetails.totalUnit}
              titlePadding={"10px 0 10px 0"}
              contentPadding={"0"}
            />
          </div>
          <div class="total-cost-container">
            <div>{infoboardEnergyConsumptionDetails.costTitle}</div>
            <div>:</div>
            <div class={isTotalCostValid ? "valid-cost" : ""}>
              {totalCost}
            </div>
          </div>
          <div class="specific-consumption-trend-card-container">
            <DelTrendCard
              baseURL={getDomainURL()}
              currentScreen={"Infoboard"}
              nodeDetails={$selectedNode}
              parameterCategory={infoboardEnergyConsumptionDetails.specificParameterCategories}
              deviceCategory={infoboardEnergyConsumptionDetails.specificDeviceCategories}
              parameterType={infoboardEnergyConsumptionDetails.specificParameterType}
              currStartTime={infoBoardSettings.currentStartTime}
              currEndTime={infoBoardSettings.currentEndTimeWRTNow}
              prevStartTime={infoBoardSettings.previousStartTime}
              prevEndTime={infoBoardSettings.previousEndTimeWRTNow}
              widgetTitle={infoboardEnergyConsumptionDetails.specificTrendCardTitle}
              trendType={infoboardEnergyConsumptionDetails.specificTrendType}
              unit={infoboardEnergyConsumptionDetails.specificUnit}
              titlePadding={"10px 0 10px 0"}
              contentPadding={"0 0 10px 0"}
            />
          </div>
        </div>
        <div class="energy-consumption-donut-container">
          <DelDonutChart
            baseURL={getDomainURL()}
            nodeDetails={$selectedNode}
            startTime={infoBoardSettings.currentStartTime}
            endTime={infoBoardSettings.currentEndTime}
            graphConfig={infoboardDonutChartConfig.chartConfig}
            chartHeight={infoboardDonutChartConfig.chartHeight}
            cutOut={infoboardDonutChartConfig.chartCutOut}
            labelOffSet={infoboardDonutChartConfig.chartLabelOffSet}
            legendPosition={"bottom"}
          />
        </div>
        .
      </div>
    </div>
    <div class="del-left-second-row-container">
      <div class="del-carbon-consumption-container del-container">
        <div class="del-carbon-consumption-container-title-container">
          {`${infoboardCarbonFootPrintConfig.mainTitle} (${
            infoBoardSettings?.currentInterval?.toUpperCase() ?? ""
          })`}
        </div>
        <div class="del-carbon-readings-container">
          <div class="del-carbon-emissions-container">
            <DelTrendCard
              baseURL={getDomainURL()}
              currentScreen={"Infoboard"}
              nodeDetails={$selectedNode}
              parameterCategory={infoboardCarbonFootPrintConfig.emissionParameterCategories}
              deviceCategory={infoboardCarbonFootPrintConfig.emissionDeviceCategories}
              parameterType={infoboardCarbonFootPrintConfig.emissionParameterType}
              currStartTime={infoBoardSettings.currentStartTime}
              currEndTime={infoBoardSettings.currentEndTimeWRTNow}
              prevStartTime={infoBoardSettings.previousStartTime}
              prevEndTime={infoBoardSettings.previousEndTimeWRTNow}
              widgetTitle={infoboardCarbonFootPrintConfig.emissionTrendCardTitle}
              trendType={infoboardCarbonFootPrintConfig.emissionTrendType}
              isTrendRequired={infoboardCarbonFootPrintConfig.emissionTrendRequired}
              unit={infoboardCarbonFootPrintConfig.emissionUnit}
              titlePadding={"10px 0 14px 0"}
              contentPadding={"0 0 10px 0"}
            />
          </div>
          <div class="del-carbon-savings-container">
            <DelTrendCard
              baseURL={getDomainURL()}
              currentScreen={"Infoboard"}
              nodeDetails={$selectedNode}
              parameterCategory={infoboardCarbonFootPrintConfig.savingsParameterCategories}
              deviceCategory={infoboardCarbonFootPrintConfig.savingsDeviceCategories}
              parameterType={infoboardCarbonFootPrintConfig.savingsParameterType}
              currStartTime={infoBoardSettings.currentStartTime}
              currEndTime={infoBoardSettings.currentEndTimeWRTNow}
              prevStartTime={infoBoardSettings.previousStartTime}
              prevEndTime={infoBoardSettings.previousEndTimeWRTNow}
              widgetTitle={infoboardCarbonFootPrintConfig.savingsTrendCardTitle}
              trendType={infoboardCarbonFootPrintConfig.savingsTrendType}
              unit={infoboardCarbonFootPrintConfig.savingsUnit}
              titlePadding={"10px 0 14px 0"}
              contentPadding={"0 0 10px 0"}
            />
          </div>
        </div>
      </div>
    </div>
    <div class="del-energy-consumption-trend-container del-container">
      <DelConsumptionTrendChart
        nodeDetails={$selectedNode}
        baseURL={getDomainURL()}
        currentScreen={"Infoboard"}
        currentStartTime={infoBoardSettings.currentStartTime}
        currentEndTime={infoBoardSettings.currentEndTimeWRTNow}
        previousStartTime={infoBoardSettings.previousStartTime}
        previousEndTime={infoBoardSettings.previousEndTime}
        groupBy={infoBoardSettings.groupBy}
        dateTimeFormat={infoBoardSettings.xAxisLabelFormat}
        hoverDateTimeFormat={infoBoardSettings.xAxisLabelHoverFormat}
        chartTitle={`${consumptionTrendChartConfig.title} (${
          infoBoardSettings?.currentInterval?.toUpperCase() ?? ""
        })`}
        chartDetails={consumptionTrendChartConfig.chartDetails}
        operation={consumptionTrendChartConfig.operation}
        yAxisTitle={consumptionTrendChartConfig.yAxisTitle}
        legendPosition={consumptionTrendChartConfig.legendPosition}
        legendAlignment={consumptionTrendChartConfig.legendAlignment}
        chartType={consumptionTrendChartConfig.chartType}
        showLegends={consumptionTrendChartConfig.showLegends}
        showOnlyCurrentData={consumptionTrendChartConfig.showOnlyCurrentData}
        showTarget={consumptionTrendChartConfig.showTarget}
        targetConfigurations={consumptionTrendChartConfig.targetConfigurations}
        chartHeight={$screenWidth > 1500
          ? "290px"
          : consumptionTrendChartConfig.chartHeight}
        hideSecondXLabel={consumptionTrendChartConfig.hideSecondXLabel}
      />
    </div>
  </div>
  <div class="del-right-container del-container">
    <DelCumulativeChart
      nodeDetails={$selectedNode}
      baseURL={getDomainURL()}
      currentScreen={"Infoboard"}
      currentStartTime={infoBoardSettings.currentStartTime}
      currentEndTime={infoBoardSettings.currentEndTimeWRTNow}
      previousStartTime={infoBoardSettings.previousStartTime}
      previousEndTime={infoBoardSettings.previousEndTime}
      groupBy={infoBoardSettings.groupBy}
      dateTimeFormat={infoBoardSettings.xAxisLabelFormat}
      title={`${cumulativeGraphConfig.title} (${
        infoBoardSettings?.currentInterval?.toUpperCase() ?? ""
      })`}
      config={cumulativeGraphConfig.parameterDetails}
      graphConfig={cumulativeGraphConfig.chartDetails}
      operation={cumulativeGraphConfig.operation}
      xAxisTitle={cumulativeGraphConfig.xAxisTitle}
      yAxisTitle={cumulativeGraphConfig.yAxisTitle}
      chartHeight={$screenWidth > 1500
        ? "668px"
        : cumulativeGraphConfig.chartHeight}
    />
  </div>
</div>

<style>
  .del-infoboard-page-container {
    padding-top: 18px;
    padding-left: 28px;
    padding-right: 28px;
    display: flex;
    gap: 18px;
    justify-content: space-between;
  }

  .del-left-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .del-right-container {
    width: calc(58% - 58px);
    height: 565px;
    padding: 12px 25px;
  }

  .del-energy-consumption-container {
    height: 211px;
  }

  .del-left-second-row-container {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    height: 124px;
  }

  .del-carbon-consumption-container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .del-carbon-readings-container {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .del-energy-consumption-trend-container {
    height: 200px;
    padding: 10px 20px;
  }

  .del-carbon-consumption-container-title-container,
  .energy-consumption-container-title-container {
    padding: 12px 0 10px 20px;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .del-carbon-consumption-container-title-container {
    padding-left: 0;
  }
  .energy-consumption-inner-container {
    padding: 0 22px 16px 20px;
    display: flex;
  }

  .energy-consumption-readings-container {
    padding-right: 15px;
    border-right: 1px dashed #c3c8d6;
    height: 152px;
    width: calc(45% - 46px);
    min-width: 230px;
  }

  .total-cost-container {
    padding: 10px 0 6px 0;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    display: flex;
    align-items: center;
    gap: 1ch;
  }

  .total-cost-container .valid-cost {
    font: normal normal bold 16px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    align-self: center;
  }

  .del-carbon-emissions-container {
    flex-grow: 1;
    border-right: 1px dashed #c3c8d6;
    padding-right: 8px;
  }
  .del-carbon-savings-container {
    width: calc(52% - 10px);
  }

  @media only screen and (min-width: 700px) {
    .del-left-container {
      width: 42%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .del-left-container,
    .del-infoboard-page-container {
      flex-wrap: wrap;
    }
    .del-right-container {
      width: calc(100% - 58px);
    }

    .del-left-container .del-energy-consumption-trend-container {
      width: calc(100% - 32px);
    }
  }

  @media only screen and (max-width: 700px) {
    .del-energy-consumption-container {
      height: max-content;
      width: inherit;
    }
    .energy-consumption-inner-container {
      flex-wrap: wrap;
    }
    .energy-consumption-readings-container {
      padding-bottom: 20px;
      border-right: none;
      border-bottom: 1px dashed #c3c8d6;
      width: calc(100% - 46px);
    }

    .del-left-container {
      width: 100%;
    }
    .del-left-second-row-container {
      flex-wrap: wrap;
      height: max-content;
    }
    .del-carbon-consumption-container,
    .del-right-container,
    .del-energy-consumption-trend-container {
      width: calc(100% - 40px);
    }
  }
  @media screen and (min-width: 1500px) {
    .del-energy-consumption-trend-container {
      height: 320px;
    }
    .del-right-container {
      height: 685px;
    }
  }
</style>
