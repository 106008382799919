<script>
  import {
    allocatedScreenNames,
    selectedNode,
    plantNodeType,
    currentPage,
    infoBoardSelectedTimePeriod,
  } from "./../../stores";
  import { infoboardScreenDetails, pathNameSSPA } from "./../../../config";
  import { ENVIRONMENT } from "./.././../../environment";
  import { Router, Link } from "svelte-routing";

  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;
  const checkIfScreenIsNotAllocated = function (screen, screenNames) {
    if (screenNames?.includes(screen)) return false;
    return true;
  };
  const handleLinkClick = function (screenName, timePeriod) {
    currentPage.set(screenName);
    infoBoardSelectedTimePeriod.set(timePeriod);
  };

  const hideInforboard = function (screen, nodeType, parentNodeType) {
    if (screen === "Infoboard" && nodeType !== parentNodeType) {
      return true;
    }
    return false;
  };
</script>

<div
  class="del-infoboard-main-dropdown-container {hideInforboard(
    infoboardScreenDetails.screenName,
    $selectedNode?.type,
    $plantNodeType
  )
    ? 'del-hidden-item'
    : ''}"
>
  <button
    class="del-nav-item {checkIfScreenIsNotAllocated(
      'Infoboard',
      $allocatedScreenNames
    )
      ? 'del-disabled'
      : ''} {$currentPage === '/Infoboard'
      ? 'del-active-nav-item'
      : ''} del-button"
    id="infoboard-button">Infoboard</button
  >
  <div
    class="del-infoboard-parent-dropdown-container {checkIfScreenIsNotAllocated(
      'Infoboard',
      $allocatedScreenNames
    )
      ? 'del-disabled'
      : ''}"
  >
    <div class="del-infoboard-dropdown-container">
      <Router>
        <div class="del-infoboard-dropdown-item">
          <Link
            to={`${pathPrefix}/Infoboard`}
            class="del-nav-item {$currentPage === '/Infoboard' &&
            $infoBoardSelectedTimePeriod === 'auto'
              ? 'del-active-nav-item'
              : ''}"
            on:click={() => handleLinkClick("/Infoboard", "auto")}>AUTO</Link
          >
        </div>
        <div class="del-infoboard-dropdown-item">
          <Link
            to={`${pathPrefix}/Infoboard`}
            class="del-nav-item {$currentPage === '/Infoboard' &&
            $infoBoardSelectedTimePeriod === 'ytd'
              ? 'del-active-nav-item'
              : ''}"
            on:click={() => handleLinkClick("/Infoboard", "ytd")}>YTD</Link
          >
        </div>
        <div class="del-infoboard-dropdown-item">
          <Link
            to={`${pathPrefix}/Infoboard`}
            class="del-nav-item {$currentPage === '/Infoboard' &&
            $infoBoardSelectedTimePeriod === 'mtd'
              ? 'del-active-nav-item'
              : ''}"
            on:click={() => handleLinkClick("/Infoboard", "mtd")}>MTD</Link
          >
        </div>
        <div class="del-infoboard-dropdown-item">
          <Link
            to={`${pathPrefix}/Infoboard`}
            class="del-nav-item {$currentPage === '/Infoboard' &&
            $infoBoardSelectedTimePeriod === 'wtd'
              ? 'del-active-nav-item'
              : ''}"
            on:click={() => handleLinkClick("/Infoboard", "wtd")}>WTD</Link
          >
        </div>
      </Router>
    </div>
  </div>
</div>

<style>
  .del-infoboard-parent-dropdown-container {
    position: relative;
  }
  #infoboard-button {
    vertical-align: top;
  }
  .del-infoboard-dropdown-container {
    display: none;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    width: 80px;
    min-height: 50px;
  }

  .del-infoboard-main-dropdown-container:hover
    :not(.del-disabled)
    .del-infoboard-dropdown-container {
    display: block;
  }

  .del-infoboard-dropdown-item {
    padding: 5px 10px;
    text-align: center;
  }

  .del-infoboard-dropdown-item:not(:last-child) {
    border-bottom: 1px solid #ccd3e7;
  }

  .del-disabled {
    pointer-events: none;
    color: #59585875;
  }

  .del-hidden-item {
    display: none;
  }
</style>
