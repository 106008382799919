<script>
  import {
    selectedNode,
    selectedDate,
    parentNodeType,
    plantNodeType,
    childNodeType,
    allocatedScreenNames,
    subPlantNodeType,
    disableScroll,
  } from "./../stores";
  import DelSortedBarChart from "./../components/DelSortedBarChart/DelSortedBarChart.svelte";
  import DelConsumptionTrendChart from "./../components/DelConsumptionTrendChart/DelConsumptionTrendChart.svelte";
  import DelLoadsTable from "./../components/DelLoadsTable/DelLoadsTable.svelte";
  import DelAlertCount from "./../components/DelAlertCount/DelAlertCount.svelte";
  import DelTrendCard from "./../components/DelTrendCard/DelTrendCard.svelte";
  import DelStackedBarChart from "./../components/DelStackedBarChart/DelStackedBarChart.svelte";
  import DelBoxPlot from "./../components/DelBoxPlot/DelBoxPlot.svelte";
  import DelDistributedBarGraph from "./../components/DelDistributedBarGraph/DelDistributedBarGraph.svelte";
  import DelMultiTrendChart from "./../components/DelMultiTrendChart/DelMultiTrendChart.svelte";
  import DelFeederBreakUp from "./../components/DelFeederBreakUp/DelFeederBreakUp.svelte";
  import { getDomainURL, invokeAPI } from "./../invokeAPI";
  import {
    highestConsumptionFeedersGraph,
    dailyEnergyConsumptionChart,
    specificConsumptionChart,
    monthlyEnergyConsumptionChart,
    realTimeAlertCount,
    historyAlertCount,
    energyConsumptionDetails,
    screensToShow,
    dailyConsumptionChartConfig,
    specificConsumptionChartConfig,
    boxPlotPower,
    boxPlotCurrent,
    boxPlotPF,
    pathNameSSPA,
    horizontalChartConfigurations,
    monthlyConsumptionChartConfig,
    feederBreakUpConfig,
  } from "./../../config";
  import { ENVIRONMENT } from "./../../environment";
  import {
    checkForNoData,
    filterParameterId,
    isSameAsToday,
    addOffSet,
    epoch13to10,
  } from "./../utilityFunctions";
  import moment from "moment";
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";

  let previousStartTime = null;
  let previousEndTime = null;
  let totalCost = "";
  let isTotalCostValid = false;
  let showModal = false;
  let showOverlay = false;
  let childNodeNames = [];
  let oldStartTime = null;
  let oldEndTime = null;

  onMount(() => {
    sessionStorage.removeItem("application-initial-load");
  });

  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;
  $: {
    if ($selectedDate) {
      const start = $selectedDate?.dayDefinedStartDateInSeconds;
      let end = $selectedDate?.dayDefinedEndDateInSeconds;
      const endNotGreaterThanNow =
        $selectedDate?.dayDefinedEndDateInSecondsNotGreaterThanNow;
      const duration = end - start;
      previousStartTime = start - duration;
      if (isSameAsToday($selectedDate?.endDateInEpoch)) {
        previousEndTime = endNotGreaterThanNow - duration;
      } else {
        previousEndTime = end - duration;
      }
      console.log(
        "Previous time periods",
        new Date(previousStartTime * 1000),
        new Date(previousEndTime * 1000)
      );
    }
  }
  $: selectedNodeId = $selectedNode?.id;
  $: currentStartTime = $selectedDate?.dayDefinedStartDateInSeconds;
  $: currentEndTime = $selectedDate?.dayDefinedEndDateInSeconds;
  $: currentEndTimeNotGreaterThanNow =
    $selectedDate?.dayDefinedEndDateInSecondsNotGreaterThanNow;
  $: nodeType = $selectedNode?.type;
  $: selectedNodeChildren = $selectedNode?.children ?? [];
  $: {
    if (selectedNodeChildren) {
      getChildNodeNames();
    }
  }
  $: {
    if (
      $selectedNode?.type === $parentNodeType ||
      $selectedNode?.type === $plantNodeType
    ) {
      isTotalCostValid = false;
      const parameterCategories =
        energyConsumptionDetails.costParameterCategories;
      const deviceCategories = energyConsumptionDetails.costDeviceCategories;
      const parameterType = energyConsumptionDetails.costParameterType;
      const parameterId = filterParameterId(
        $selectedNode,
        parameterCategories,
        deviceCategories,
        parameterType
      );

      if (parameterId && $selectedDate) {
        const endTime = $selectedDate.dayDefinedEndDateInSeconds;
        const startTime = $selectedDate.dayDefinedStartDateInSeconds;
        invokeCostAPI(startTime, endTime, parameterId);
      }
    }
  }

  const getChildNodeNames = function () {
    childNodeNames = [];
    if (selectedNodeChildren?.length > 0) {
      const name = selectedNodeChildren[0].name?.split(" ");
      if (name?.length) {
        childNodeNames.push(name[0]);
      }
    }
    if (selectedNodeChildren?.length > 1) {
      const name = selectedNodeChildren[1].name?.split(" ");
      if (name?.length) {
        childNodeNames.push(name[0]);
      }
    }
  };

  const costAPIOnSuccess = function (data) {
    if (data?.length) {
      const result = checkForNoData(data[0].Result);
      if (result) {
        totalCost = `${energyConsumptionDetails.costUnit} ${Math.round(
          result
        ).toLocaleString(energyConsumptionDetails.costFormat)}`;
        isTotalCostValid = true;
      } else {
        totalCost = "No Data";
      }
    } else {
      totalCost = "No Data";
    }
  };

  const costAPIOnError = function () {
    totalCost = "No Data";
  };

  const invokeCostAPI = function (startTime, endTime, parameterId) {
    const endpoint = "dpe/api/evaluator";
    if (oldStartTime !== startTime || oldEndTime !== endTime) {
      oldStartTime = startTime;
      oldEndTime = endTime;
      const payload = {
        endTime,
        startTime,
        parameters: [parameterId],
      };
      totalCost = "Loading...";

      invokeAPI(
        "POST",
        endpoint,
        costAPIOnSuccess,
        costAPIOnError,
        payload,
        "Dashboard/R"
      );
    }
  };

  const monthlyGraphStartTime = epoch13to10(
    addOffSet(
      parseInt(moment().subtract(11, "months").startOf("month").valueOf())
    )
  );
  const monthlyGraphEndTime = parseInt(moment().valueOf() / 1000);

  const onViewAllButtonClickFeeders = function () {
    showModal = true;
    showOverlay = true;
    disableScroll.set(true);
  };

  const onOverLayClick = function () {
    showModal = false;
    showOverlay = false;
    disableScroll.set(false);
  };

  const navigateToEventsPage = function (category) {
    let filters = {
      currentstatus: ["Active"],
    };
    if (category) {
      filters.category = [category];
    }
    sessionStorage.setItem(
      "eventViewerSelectedFilters",
      JSON.stringify(filters)
    );
    window.location.href = `${pathPrefix}/Events`;
  };

  const navigateToEventReports = function (category) {
    const reportSelectNodeId = $selectedNode?.id || "";
    const reportSelectedNodeName = $selectedNode?.name || "";
    const reportStartTime = $selectedDate?.dayDefinedStartDateInSeconds || 0;
    const reportEndTime =
      $selectedDate?.dayDefinedEndDateInSecondsNotGreaterThanNow || 0;
    const alertCategories = category
      ? [category]
      : ["Critical", "Warning", "Informational"];
    const reportToBeShown = "Events";
    sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
    sessionStorage.setItem("report-selected-node-name", reportSelectedNodeName);
    sessionStorage.setItem("report-selected-start-time", reportStartTime);
    sessionStorage.setItem("report-selected-end-time", reportEndTime);
    sessionStorage.setItem("report-to-be-shown", reportToBeShown);
    sessionStorage.setItem(
      "report-default-selected-options",
      JSON.stringify(alertCategories)
    );
    sessionStorage.setItem(
      "report-selected-options",
      JSON.stringify(alertCategories)
    );
    window.location.href = `${pathPrefix}/Reports`;
  };
</script>

<div class="del-page-container">
  {#if showOverlay}<div
      class="del-dashboard-overlay-container"
      on:click={() => {
        onOverLayClick();
      }}
      on:keypress={() => {
        onOverLayClick();
      }}
    />{/if}
  {#if nodeType === $parentNodeType || $selectedNode?.type === $plantNodeType}
    <div>
      <div class="del-dashboard-container">
        <div class="del-left-container">
          <div
            class="left-inner-container energy-consumption-container energy-consumption-cost-container"
          >
            <div class="del-container del-left-consumption-container">
              <div
                class={`energy-consumption-container-title-container ${
                  $selectedNode?.type === $parentNodeType
                    ? "first-dashboard"
                    : ""
                }`}
              >
                {energyConsumptionDetails.mainTitle}
              </div>
              <div class="energy-consumption-inner-container">
                <div class="energy-consumption-readings-container">
                  <div class="total-consumption-trend-card-container">
                    <DelTrendCard
                      baseURL={getDomainURL()}
                      currentScreen={"Dashboard"}
                      nodeDetails={$selectedNode}
                      parameterCategory={energyConsumptionDetails.totalParameterCategories}
                      deviceCategory={energyConsumptionDetails.totalDeviceCategories}
                      parameterType={energyConsumptionDetails.totalParameterType}
                      currStartTime={currentStartTime}
                      currEndTime={currentEndTimeNotGreaterThanNow}
                      prevStartTime={previousStartTime}
                      prevEndTime={previousEndTime}
                      widgetTitle={`${energyConsumptionDetails.totalTrendCardTitle} (${energyConsumptionDetails.totalUnit})`}
                      trendType={energyConsumptionDetails.totalTrendType}
                      unit={""}
                      titlePadding={$selectedNode?.type === $parentNodeType
                        ? "16px 0 0 22px"
                        : ""}
                    />
                  </div>
                  {#if $selectedNode?.type === $parentNodeType}
                    <div class="kwh-break-up-container">
                      <div class="kwh-break-up">
                        <DelTrendCard
                          baseURL={getDomainURL()}
                          currentScreen={"Dashboard"}
                          nodeDetails={selectedNodeChildren?.length > 0
                            ? selectedNodeChildren[0]
                            : null}
                          parameterCategory={energyConsumptionDetails.totalParameterCategories}
                          deviceCategory={energyConsumptionDetails.totalDeviceCategories}
                          parameterType={energyConsumptionDetails.totalParameterType}
                          currStartTime={currentStartTime}
                          currEndTime={currentEndTimeNotGreaterThanNow}
                          prevStartTime={previousStartTime}
                          prevEndTime={previousEndTime}
                          widgetTitle={`${
                            childNodeNames?.length ? childNodeNames[0] : ""
                          } (${energyConsumptionDetails.totalUnit})`}
                          isTrendRequired={false}
                          unit={""}
                          titlePadding={"16px 0 0 22px"}
                        />
                      </div>
                      <div class="kwh-break-up">
                        <DelTrendCard
                          baseURL={getDomainURL()}
                          currentScreen={"Dashboard"}
                          nodeDetails={selectedNodeChildren?.length > 1
                            ? selectedNodeChildren[1]
                            : null}
                          parameterCategory={energyConsumptionDetails.totalParameterCategories}
                          deviceCategory={energyConsumptionDetails.totalDeviceCategories}
                          parameterType={energyConsumptionDetails.totalParameterType}
                          currStartTime={currentStartTime}
                          currEndTime={currentEndTimeNotGreaterThanNow}
                          prevStartTime={previousStartTime}
                          prevEndTime={previousEndTime}
                          widgetTitle={`${
                            childNodeNames?.length > 1 ? childNodeNames[1] : ""
                          } (${energyConsumptionDetails.totalUnit})`}
                          isTrendRequired={false}
                          unit={""}
                          titlePadding={"16px 0 0 22px"}
                        />
                      </div>
                    </div>
                  {/if}
                  <div class="total-cost-container">
                    <div>{energyConsumptionDetails.costTitle}</div>
                    <div>:</div>
                    <div class={isTotalCostValid ? "valid-cost" : ""}>
                      {totalCost}
                    </div>
                  </div>
                  {#if $selectedNode?.type === $plantNodeType}
                    <div class="specific-consumption-trend-card-container">
                      <DelTrendCard
                        baseURL={getDomainURL()}
                        currentScreen={"Dashboard"}
                        nodeDetails={$selectedNode}
                        parameterCategory={energyConsumptionDetails.specificParameterCategories}
                        deviceCategory={energyConsumptionDetails.specificDeviceCategories}
                        parameterType={energyConsumptionDetails.specificParameterType}
                        currStartTime={currentStartTime}
                        currEndTime={currentEndTimeNotGreaterThanNow}
                        prevStartTime={previousStartTime}
                        prevEndTime={previousEndTime}
                        widgetTitle={`${energyConsumptionDetails.specificTrendCardTitle} (${energyConsumptionDetails.specificUnit})`}
                        trendType={energyConsumptionDetails.specificTrendType}
                        unit={""}
                      />
                    </div>
                  {/if}
                </div>
              </div>
            </div>
            <div class="del-container del-right-cost-container">
              <DelDistributedBarGraph
                baseURL={getDomainURL()}
                startTime={currentStartTime}
                endTime={currentEndTime}
                nodeDetails={$selectedNode}
                chartTitle={horizontalChartConfigurations?.chartTitle}
                chartHeight={horizontalChartConfigurations?.chartHeight}
                legendPosition={horizontalChartConfigurations?.legendPosition}
                requiredParameters={horizontalChartConfigurations?.requiredParameters}
                showYLabels={horizontalChartConfigurations?.showYLabels}
              />
            </div>
          </div>
          <div
            class="del-container left-inner-container higest-consumption-container"
          >
            <DelSortedBarChart
              nodeDetails={$selectedNode}
              baseURL={getDomainURL()}
              levelType={$childNodeType}
              isHorizontal={true}
              {currentStartTime}
              currentEndTime={currentEndTimeNotGreaterThanNow}
              {previousStartTime}
              {previousEndTime}
              chartHeight={highestConsumptionFeedersGraph.chartHeight}
              chartTitle={highestConsumptionFeedersGraph.title}
              parameterCategories={highestConsumptionFeedersGraph.parameterCategories}
              deviceCategories={highestConsumptionFeedersGraph.deviceCategories}
              chartConfigurations={highestConsumptionFeedersGraph.chartConfigurations}
              xAxisTitle={highestConsumptionFeedersGraph.xAxisTitle}
              onViewAllButtonClick={onViewAllButtonClickFeeders}
              showViewAllButton={true}
            />
          </div>
        </div>
        <div class="del-right-container">
          <div class="right-inner-container event-count-main-container">
            <div class="del-container event-count-container">
              <DelAlertCount
                baseURL={getDomainURL()}
                isRealTime={true}
                selectedNodeDetails={$selectedNode}
                currentNodeId={$selectedNode?.id}
                title={realTimeAlertCount.title}
                uniqueId={realTimeAlertCount.uniqueId}
                viewAllClicked={navigateToEventsPage}
                categoryClicked={navigateToEventsPage}
                isViewAll={$allocatedScreenNames?.includes(
                  screensToShow["/Events"]?.screen
                )}
              />
            </div>
            <div class="del-container event-count-container">
              <DelAlertCount
                baseURL={getDomainURL()}
                isRealTime={false}
                selectedNodeDetails={$selectedNode}
                currentNodeId={$selectedNode?.id}
                title={historyAlertCount.title}
                uniqueId={historyAlertCount.uniqueId}
                viewAllClicked={navigateToEventReports}
                categoryClicked={navigateToEventReports}
                isViewAll={$allocatedScreenNames?.includes(
                  screensToShow["/Reports"]?.screen
                )}
                startTime={currentStartTime}
                endTime={currentEndTime}
              />
            </div>
          </div>
          <div
            class="del-container right-inner-container parent-node-dashboard daily-graph-container"
          >
            <DelStackedBarChart
              baseURL={getDomainURL()}
              nodeDetails={$selectedNode}
              startTime={currentStartTime}
              endTime={currentEndTimeNotGreaterThanNow}
              graphConfig={dailyConsumptionChartConfig.chartConfig}
              chartTitle={dailyConsumptionChartConfig.title}
              xAxisTitle={dailyConsumptionChartConfig.xAxisTitle}
              yAxisTitle={dailyConsumptionChartConfig.yAxisTitle}
              showLegends={dailyConsumptionChartConfig.showLegends}
              legendAlignment={dailyConsumptionChartConfig.legendAlignment}
              legendPosition={dailyConsumptionChartConfig.legendPosition}
              includeAllDays={dailyConsumptionChartConfig.includeAllDays}
              chartHeight={dailyConsumptionChartConfig.chartHeight}
              xAxisFormat={dailyConsumptionChartConfig.xAxisFormat}
              unit={dailyConsumptionChartConfig.unit}
              groupBy={dailyConsumptionChartConfig.groupBy}
              operation={dailyConsumptionChartConfig.operation}
              showFooter={dailyConsumptionChartConfig?.showFooter}
            />
          </div>
          <div
            class="del-container right-inner-container parent-node-dashboard daily-graph-container"
          >
            <DelStackedBarChart
              baseURL={getDomainURL()}
              nodeDetails={$selectedNode}
              startTime={currentStartTime}
              endTime={currentEndTimeNotGreaterThanNow}
              graphConfig={specificConsumptionChartConfig.chartConfig}
              chartTitle={specificConsumptionChartConfig.title}
              xAxisTitle={specificConsumptionChartConfig.xAxisTitle}
              yAxisTitle={specificConsumptionChartConfig.yAxisTitle}
              showLegends={specificConsumptionChartConfig.showLegends}
              legendAlignment={specificConsumptionChartConfig.legendAlignment}
              legendPosition={specificConsumptionChartConfig.legendPosition}
              includeAllDays={specificConsumptionChartConfig.includeAllDays}
              chartHeight={specificConsumptionChartConfig.chartHeight}
              xAxisFormat={specificConsumptionChartConfig.xAxisFormat}
              unit={specificConsumptionChartConfig.unit}
              groupBy={specificConsumptionChartConfig.groupBy}
              operation={specificConsumptionChartConfig.operation}
              showFooter={specificConsumptionChartConfig?.showFooter}
            />
          </div>
        </div>
      </div>
      <div class="del-container del-dashboard-monthly-chart-container">
        <DelMultiTrendChart
          baseURL={getDomainURL()}
          currentScreen={"Dashboard"}
          nodeDetails={$selectedNode}
          startTime={monthlyGraphStartTime}
          endTime={monthlyGraphEndTime}
          chartDetails={monthlyConsumptionChartConfig?.chartConfig}
          yAxisDetails={monthlyConsumptionChartConfig?.yAxisDetails}
          legendPosition={monthlyConsumptionChartConfig?.legendPosition}
          groupBy={monthlyConsumptionChartConfig?.groupBy}
          operation={monthlyConsumptionChartConfig?.operation}
          chartTitle={monthlyConsumptionChartConfig?.title}
          chartHeight={monthlyConsumptionChartConfig?.chartHeight}
          legendAlignment={monthlyConsumptionChartConfig?.legendAlignment}
        ></DelMultiTrendChart>
      </div>
    </div>
  {:else if nodeType && $parentNodeType}
    <div class="del-sub-dashboard-container">
      <div class="del-dashboard-container">
        <div class="del-left-container-sub">
          <div class="left-top-container">
            <div class="del-container energy-consumption-container">
              <div class="energy-consumption-container-title-container">
                {energyConsumptionDetails.mainTitle}
              </div>
              <div class="total-consumption-trend-card-container">
                <DelTrendCard
                  baseURL={getDomainURL()}
                  currentScreen={"Dashboard"}
                  nodeDetails={$selectedNode}
                  parameterCategory={energyConsumptionDetails.totalParameterCategories}
                  deviceCategory={energyConsumptionDetails.totalDeviceCategories}
                  parameterType={energyConsumptionDetails.totalParameterType}
                  currStartTime={currentStartTime}
                  currEndTime={currentEndTimeNotGreaterThanNow}
                  prevStartTime={previousStartTime}
                  prevEndTime={previousEndTime}
                  widgetTitle={`${energyConsumptionDetails.totalTrendCardTitle} (${energyConsumptionDetails.totalUnit})`}
                  trendType={energyConsumptionDetails.totalTrendType}
                  unit={""}
                />
              </div>
              <div class="specific-consumption-trend-card-container">
                <DelTrendCard
                  baseURL={getDomainURL()}
                  currentScreen={"Dashboard"}
                  nodeDetails={$selectedNode}
                  parameterCategory={energyConsumptionDetails.specificParameterCategories}
                  deviceCategory={energyConsumptionDetails.specificDeviceCategories}
                  parameterType={energyConsumptionDetails.specificParameterType}
                  currStartTime={currentStartTime}
                  currEndTime={currentEndTimeNotGreaterThanNow}
                  prevStartTime={previousStartTime}
                  prevEndTime={previousEndTime}
                  widgetTitle={`${energyConsumptionDetails.specificTrendCardTitle} (${energyConsumptionDetails.specificUnit})`}
                  trendType={energyConsumptionDetails.specificTrendType}
                  unit={""}
                />
              </div>
            </div>
            <div class="event-count-main-container">
              <div class="del-container event-count-container">
                <DelAlertCount
                  baseURL={getDomainURL()}
                  isRealTime={true}
                  selectedNodeDetails={$selectedNode}
                  currentNodeId={$selectedNode?.id}
                  title={realTimeAlertCount.title}
                  uniqueId={`${realTimeAlertCount.uniqueId}-feeder-dashboard`}
                  viewAllClicked={navigateToEventsPage}
                  categoryClicked={navigateToEventsPage}
                  isViewAll={$allocatedScreenNames?.includes(
                    screensToShow["/Events"]?.screen
                  )}
                />
              </div>
              <div class="del-container event-count-container">
                <DelAlertCount
                  baseURL={getDomainURL()}
                  isRealTime={false}
                  selectedNodeDetails={$selectedNode}
                  currentNodeId={$selectedNode?.id}
                  title={historyAlertCount.title}
                  uniqueId={`${historyAlertCount.uniqueId}-feeder-dashboard`}
                  viewAllClicked={navigateToEventReports}
                  categoryClicked={navigateToEventReports}
                  isViewAll={$allocatedScreenNames?.includes(
                    screensToShow["/Reports"]?.screen
                  )}
                  startTime={currentStartTime}
                  endTime={currentEndTime}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="del-right-container-sub">
          <div
            class="del-container right-inner-container top-daily-graph-container daily-graph-container"
          >
            <DelConsumptionTrendChart
              nodeDetails={$selectedNode}
              baseURL={getDomainURL()}
              {currentStartTime}
              {currentEndTime}
              showOnlyCurrentData={true}
              chartDetails={dailyEnergyConsumptionChart.chartDetails}
              chartTitle={dailyEnergyConsumptionChart.title}
              chartHeight={dailyEnergyConsumptionChart.chartHeight}
              operation={dailyEnergyConsumptionChart.operation}
              groupBy={dailyEnergyConsumptionChart.groupBy}
              yAxisTitle={dailyEnergyConsumptionChart.yAxisTitle}
              dateTimeFormat={dailyEnergyConsumptionChart.dateTimeFormat}
              hoverDateTimeFormat={dailyEnergyConsumptionChart.dateTimeFormatOnHover}
              showLegends={dailyEnergyConsumptionChart.showLegends}
            />
          </div>
        </div>
      </div>
      <div class="del-dashboard-container">
        <div class="del-left-container-sub">
          <div
            class="del-container right-inner-container daily-graph-container"
          >
            <DelConsumptionTrendChart
              nodeDetails={$selectedNode}
              baseURL={getDomainURL()}
              showOnlyCurrentData={true}
              currentStartTime={monthlyGraphStartTime}
              currentEndTime={monthlyGraphEndTime}
              chartType={monthlyEnergyConsumptionChart.chartType}
              chartDetails={monthlyEnergyConsumptionChart.chartDetails}
              chartTitle={monthlyEnergyConsumptionChart.title}
              chartHeight={monthlyEnergyConsumptionChart.chartHeight}
              operation={monthlyEnergyConsumptionChart.operation}
              groupBy={monthlyEnergyConsumptionChart.groupBy}
              yAxisTitle={monthlyEnergyConsumptionChart.yAxisTitle}
              dateTimeFormat={monthlyEnergyConsumptionChart.dateTimeFormat}
              hoverDateTimeFormat={monthlyEnergyConsumptionChart.dateTimeFormatOnHover}
              showLegends={monthlyEnergyConsumptionChart.showLegends}
            />
          </div>
        </div>
        <div class="del-right-container-sub">
          <div
            class="del-container right-inner-container daily-graph-container"
          >
            <DelConsumptionTrendChart
              nodeDetails={$selectedNode}
              baseURL={getDomainURL()}
              {currentStartTime}
              {currentEndTime}
              showOnlyCurrentData={true}
              chartDetails={specificConsumptionChart.chartDetails}
              chartTitle={specificConsumptionChart.title}
              chartHeight={specificConsumptionChart.chartHeight}
              operation={specificConsumptionChart.operation}
              groupBy={specificConsumptionChart.groupBy}
              yAxisTitle={specificConsumptionChart.yAxisTitle}
              dateTimeFormat={specificConsumptionChart.dateTimeFormat}
              hoverDateTimeFormat={specificConsumptionChart.dateTimeFormatOnHover}
              showLegends={specificConsumptionChart.showLegends}
            />
          </div>
        </div>
      </div>
      <div class="del-boxplot-parent-container">
        <div class="del-container del-boxplot-container">
          <DelBoxPlot
            baseURL={getDomainURL()}
            currentScreen={"Dashboard"}
            nodeDetails={$selectedNode}
            {currentStartTime}
            currentEndTime={currentEndTimeNotGreaterThanNow}
            {previousStartTime}
            {previousEndTime}
            title={boxPlotPower.title}
            deviceCategory={boxPlotPower.deviceCategory}
            parameterCategory={boxPlotPower.parameterCategory}
            legendAlignment={boxPlotPower.legendAlignment}
            legendPosition={boxPlotPower.legendPosition}
            currentColor={boxPlotPower.currentColor}
            previousColor={boxPlotPower.previousColor}
            currentLabel={boxPlotPower.currentLabel}
            previousLabel={boxPlotPower.previousLabel}
            unit={boxPlotPower.unit}
            uniqueId={boxPlotPower.uniqueId}
            chartHeight={boxPlotPower.chartHeight}
          />
        </div>
        <div class="del-container del-boxplot-container">
          <DelBoxPlot
            baseURL={getDomainURL()}
            currentScreen={"Dashboard"}
            nodeDetails={$selectedNode}
            {currentStartTime}
            currentEndTime={currentEndTimeNotGreaterThanNow}
            {previousStartTime}
            {previousEndTime}
            title={boxPlotCurrent.title}
            deviceCategory={boxPlotCurrent.deviceCategory}
            parameterCategory={boxPlotCurrent.parameterCategory}
            legendAlignment={boxPlotCurrent.legendAlignment}
            legendPosition={boxPlotCurrent.legendPosition}
            currentColor={boxPlotCurrent.currentColor}
            previousColor={boxPlotCurrent.previousColor}
            currentLabel={boxPlotCurrent.currentLabel}
            previousLabel={boxPlotCurrent.previousLabel}
            unit={boxPlotCurrent.unit}
            uniqueId={boxPlotCurrent.uniqueId}
            chartHeight={boxPlotCurrent.chartHeight}
          />
        </div>
        <div class="del-container del-boxplot-container">
          <DelBoxPlot
            baseURL={getDomainURL()}
            currentScreen={"Dashboard"}
            nodeDetails={$selectedNode}
            {currentStartTime}
            currentEndTime={currentEndTimeNotGreaterThanNow}
            {previousStartTime}
            {previousEndTime}
            title={boxPlotPF.title}
            deviceCategory={boxPlotPF.deviceCategory}
            parameterCategory={boxPlotPF.parameterCategory}
            legendAlignment={boxPlotPF.legendAlignment}
            legendPosition={boxPlotPF.legendPosition}
            currentColor={boxPlotPF.currentColor}
            previousColor={boxPlotPF.previousColor}
            currentLabel={boxPlotPF.currentLabel}
            previousLabel={boxPlotPF.previousLabel}
            unit={boxPlotPF.unit}
            uniqueId={boxPlotPF.uniqueId}
            chartHeight={boxPlotPF.chartHeight}
          />
        </div>
      </div>
    </div>
    {#if nodeType === $subPlantNodeType}
      <div class="del-feeder-break-up-container">
        <DelFeederBreakUp
          baseURL={getDomainURL()}
          currentScreen={"Dashboard"}
          nodeDetails={$selectedNode}
          startTime={currentStartTime}
          endTime={currentEndTimeNotGreaterThanNow}
          parameterCategories={feederBreakUpConfig?.parameterCategories}
          deviceCategories={feederBreakUpConfig?.deviceCategories}
          title={feederBreakUpConfig?.title}
          unit={feederBreakUpConfig?.unit}
        ></DelFeederBreakUp>
      </div>
    {/if}
    <div class="del-dashboard-loads-table-container">
      <DelLoadsTable nodeId={selectedNodeId} baseURL={getDomainURL()} />
    </div>
  {/if}
  {#if showModal}
    <div class="del-highest-feeder-modal">
      <div class="del-highest-feeder-modal-header">
        <div class="del-highest-feeder-modal-title">
          {highestConsumptionFeedersGraph.title}
        </div>
        <div>
          <button
            class="del-highest-feeder-modal-close-button"
            on:click={() => {
              showModal = false;
              showOverlay = false;
              disableScroll.set(false);
            }}><Icon icon="iconamoon:close" /></button
          >
        </div>
      </div>
      <div class="del-highest-feeder-modal-content-container">
        <DelSortedBarChart
          nodeDetails={$selectedNode}
          baseURL={getDomainURL()}
          levelType={$childNodeType}
          isHorizontal={true}
          {currentStartTime}
          currentEndTime={currentEndTimeNotGreaterThanNow}
          {previousStartTime}
          {previousEndTime}
          chartHeight={"800px"}
          parameterCategories={highestConsumptionFeedersGraph.parameterCategories}
          deviceCategories={highestConsumptionFeedersGraph.deviceCategories}
          chartConfigurations={highestConsumptionFeedersGraph.chartConfigurations}
          xAxisTitle={highestConsumptionFeedersGraph.xAxisTitle}
          count={25}
        />
      </div>
    </div>
  {/if}
</div>

<style>
  .del-dashboard-container {
    padding: 18px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .del-sub-dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 18px 28px;
  }

  .del-sub-dashboard-container .del-dashboard-container {
    padding: 0;
  }

  .del-left-container {
    width: calc(44% - 10px);
  }
  .del-right-container {
    width: calc(56% - 10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
  }

  .del-left-container-sub,
  .del-right-container-sub {
    width: calc(50% - 8px);
  }

  .right-inner-container {
    width: 100%;
  }

  .energy-consumption-container {
    height: 272px;
    margin-bottom: 16px;
  }

  .energy-consumption-cost-container {
    display: flex;
    gap: 16px;
  }
  .higest-consumption-container {
    height: 266px;
    padding: 12px 0;
    padding-right: 24px;
  }

  .event-count-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }
  .left-top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }

  .left-top-container .event-count-main-container {
    width: calc(63% - 8px);
    flex-direction: column;
    flex-grow: 1;
  }
  .left-top-container .event-count-container {
    height: 128px;
    width: 100%;
  }
  .left-top-container .energy-consumption-container {
    width: calc(37% - 8px);
    margin-bottom: 0;
    padding-right: 22px;
    max-width: 300px;
  }

  .event-count-container {
    height: 106px;
    width: calc(50% - 10px);
  }

  .daily-graph-container {
    height: 196px;
  }

  .parent-node-dashboard.daily-graph-container {
    padding: 12px 22px;
    width: calc(100% - 44px);
  }

  .del-sub-dashboard-container .daily-graph-container {
    height: 228px;
    padding: 12px 16px;
    width: calc(100% - 32px);
  }

  .del-right-container-sub .top-daily-graph-container.daily-graph-container {
    height: 250px;
  }

  .del-boxplot-parent-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
  }
  .del-boxplot-container {
    height: 225px;
    width: calc(33.33% - 40px);
    padding: 12px;
  }

  .del-dashboard-loads-table-container {
    margin: 0 28px;
  }

  :global(.del-dashboard-container .del-consumption-trend-chart-title) {
    padding-bottom: 12px !important;
  }

  .energy-consumption-container-title-container {
    padding: 16px 0 20px 22px;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .energy-consumption-container-title-container.first-dashboard {
    padding-bottom: 10px;
  }

  .energy-consumption-inner-container {
    padding: 0 22px 28px 0px;
    display: flex;
  }

  .del-left-consumption-container {
    width: 46%;
    max-width: 300px;
  }

  .del-left-consumption-container .energy-consumption-readings-container {
    width: 100%;
    padding-right: 0;
  }

  .del-right-cost-container {
    flex-grow: 1;
    padding: 16px 0;
  }

  .energy-consumption-cost-container .energy-consumption-readings-container {
    border: none;
  }

  .energy-consumption-readings-container {
    padding-right: 25px;
    border-right: 1px dashed #c3c8d6;
    height: 204px;
    width: 41%;
  }
  .kwh-break-up-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0 10px;
  }

  .kwh-break-up {
    flex-grow: 1;
  }
  .total-cost-container {
    padding: 22px;
    padding-right: 0;
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    display: flex;
    align-items: center;
    gap: 1ch;
  }

  .total-cost-container .valid-cost {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #2d3860;
    align-self: center;
  }

  .del-sub-dashboard-container .total-consumption-trend-card-container {
    padding-bottom: 55px;
  }

  .del-dashboard-monthly-chart-container {
    margin: 16px 28px 0;
    padding: 12px 22px;
  }

  .del-feeder-break-up-container {
    padding: 0 28px 18px;
  }

  /* For Modal */

  .del-dashboard-overlay-container {
    position: fixed;
    z-index: 108;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  .del-highest-feeder-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 10%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-highest-feeder-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }

  .del-highest-feeder-modal-content-container {
    font: normal normal bold 15px Roboto;
    color: #000000;
    padding: 15px 0;
    position: relative;
    max-height: 450px;
    overflow-y: auto;
    min-height: 300px;
    padding-top: 0;
  }

  .del-highest-feeder-modal-close-button {
    background: transparent;
    font-size: larger;
    color: #63686d;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  @media screen and (min-width: 700px) {
    .del-highest-feeder-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 10%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-highest-feeder-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 10%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-highest-feeder-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-highest-feeder-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-highest-feeder-modal {
      width: 800px;
      left: calc(50% - 400px);
      top: 10%;
    }
  }

  /* End of modal styles */

  @media screen and (max-width: 1100px) {
    .del-left-container,
    .del-right-container,
    .del-left-container-sub,
    .del-right-container-sub,
    .del-boxplot-container {
      width: 100%;
    }
  }
  @media screen and (max-width: 650px) {
    .energy-consumption-cost-container {
      flex-wrap: wrap;
      height: max-content;
    }
    .energy-consumption-cost-container .del-left-consumption-container {
      flex-grow: 1;
      max-width: none;
    }
    .event-count-main-container {
      flex-wrap: wrap;
    }
    .event-count-main-container .event-count-container {
      width: 100%;
    }
    .del-left-container-sub .left-top-container {
      flex-wrap: wrap;
    }
    .del-left-container-sub .left-top-container .energy-consumption-container {
      flex-grow: 1;
      max-width: none;
    }
  }
</style>
