// Common Configurations
export const PREVIEW_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const DEV_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const QA_DOMAIN_URL = "https://qanewplatformapi.delpheon.io";
export const PRODUCTION_DOMAIN_URL = "https://prodnewplatformapi.delpheon.io";

export const PREVIEW_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const DEV_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const QA_ANALYTICS_DOMAIN_URL = "https://analytics-ae-qanew.delpheon.io";
export const PRODUCTION_ANALYTICS_DOMAIN_URL =
  "https://analytics-prodnew.delpheon.io";

export const PREVIEW_DEPLOYED_URL = "http://localhost:3000";
export const DEV_DEPLOYED_URL = "https://epmvguard-devnew.delpheon.io";
export const QA_DEPLOYED_URL = "https://epmvguard-qanew.delpheon.io";
export const PRODUCTION_DEPLOYED_URL = "https://epmvguard-prodnew.delpheon.io";

export const APP_VERSION = "2.0.6";

// Configurations for Header

export const pathNameSSPA = "/epm_vguard";
export const infoboardScreenDetails = {
  show: true,
  screenName: "Infoboard",
  displayName: "Infoboard",
};
export const pagesWithDatePicker = ["Dashboard", "Reports", "Plan Input"]; //should be the corresponding displayName
export const screensToShow = {
  "/Infoboard": { screen: "Infoboard", displayName: "Infoboard" },
  "/": { screen: "Dashboard", displayName: "Dashboard" },
  "/Reports": { screen: "Reports", displayName: "Reports" },
  "/Events": { screen: "Events", displayName: "Events" },
  "/DigitalTwin": { screen: "Digital Twin", displayName: "Digital Twin" },
  "/PlanInput": { screen: "Plant Input", displayName: "Plant Input" },
  "/Analytics": { screen: "Analytics", displayName: "Analytics" },
  "/Error": { screen: "Dashboard", displayName: "Error" },
};

// Configurations for Dashboard

// Highest Consumption Feeders Graph

export const highestConsumptionFeedersGraph = {
  title: "Highest Consumption Feeders",
  parameterCategories: ["ondemandActiveEnergy"],
  deviceCategories: ["Feeder Meter"],
  chartConfigurations: {
    currentChartColor: "#3F4C84",
    previousChartColor: "#BFDDE5",
    currentDisplayName: "Current",
    previousDisplayName: "Previous",
  },
  xAxisTitle: "kWh",
  chartHeight: "250px",
};

// Daily Energy Consumption Chart
export const dailyEnergyConsumptionChart = {
  title: "Daily Energy Consumption",
  chartHeight: "220px",
  dateTimeFormatOnHover: "DD MMM YY",
  dateTimeFormat: "DD",
  yAxisTitle: "kWh",
  operation: "sum",
  groupBy: "day",
  chartDetails: {
    parameterCategories: ["dailyActiveEnergy"],
    deviceCategories: ["Feeder Meter"],
    parameterType: "Derived",
    currentChartColor: "#FFA375",
    currentDisplayName: "Energy",
  },
  showLegends: false,
};

// Specific Consumption Chart
export const specificConsumptionChart = {
  title: "Specific Consumption",
  chartHeight: "220px",
  dateTimeFormatOnHover: "DD MMM YY",
  dateTimeFormat: "DD",
  yAxisTitle: "kwh/Unit",
  operation: "sum",
  groupBy: "day",
  chartDetails: {
    parameterCategories: [
      "daily_Sp_cons_IST Lab Meter",
      "daily_Sp_cons_SSB5 Meter",
      "daily_Sp_cons_SSB6 Meter",
      "daily_Sp_cons_APFC Meter",
      "daily_Sp_cons_MLSB Meter",
      "daily_Sp_cons_SSB7 Meter",
      "daily_Sp_cons_Spare Meter",
      "daily_Sp_cons_SSB8 Meter",
      "daily_Sp_cons_SSB9 Meter",
      "daily_Sp_cons_SSB3 Meter",
      "daily_Sp_cons_SSB2 Meter",
      "daily_Sp_cons_APFC-2 Meter",
    ],
    deviceCategories: ["Enterprise"],
    parameterType: "Derived",
    currentChartColor: "#AEDFFF",
    currentDisplayName: "Energy",
  },
  showLegends: false,
};

// Energy Consumption Monthly Trends Chart
export const monthlyEnergyConsumptionChart = {
  title: "Energy Consumption Monthly Trends (12 Months)",
  chartHeight: "220px",
  dateTimeFormatOnHover: "MMM YYYY",
  dateTimeFormat: "MMM YY",
  yAxisTitle: "kWh",
  operation: "sum",
  groupBy: "month",
  chartType: "line",
  chartDetails: {
    parameterCategories: ["dailyActiveEnergy"],
    deviceCategories: ["Feeder Meter"],
    parameterType: "Derived",
    currentChartColor: "#D20000",
    currentDisplayName: "Energy",
    currentBorderColor: "#707070",
    pointRadius: 5,
  },
  showLegends: false,
};

// Real Time Alert Count Widget
export const realTimeAlertCount = {
  title: "Real-time Events",
  uniqueId: "real-time-alert-count",
};

// Real Time Alert Count Widget
export const historyAlertCount = {
  title: "Event History",
  uniqueId: "alert-history-count",
};

// For the energy consumption trend cards and cost
export const energyConsumptionDetails = {
  mainTitle: "Energy Consumption",
  totalTrendCardTitle: "Total",
  totalParameterCategories: ["ondemandActiveEnergy"],
  totalDeviceCategories: ["Total Meter", "Feeder Meter"],
  totalParameterType: "Derived",
  totalTrendType: "Consumption",
  totalTrendRequired: true,
  totalUnit: "kWh",
  specificTrendCardTitle: "Specific",
  specificParameterCategories: [
    "ondemand_Sp_cons_SikkimPlant",
    "od_Sp_cons_IST Lab Meter",
    "od_Sp_cons_SSB5 Meter",
    "od_Sp_cons_SSB6 Meter",
    "od_Sp_cons_APFC Meter",
    "od_Sp_cons_MLSB Meter",
    "od_Sp_cons_SSB7 Meter",
    "od_Sp_cons_Spare Meter",
    "od_Sp_cons_SSB8 Meter",
    "od_Sp_cons_SSB9 Meter",
    "od_Sp_cons_SSB3 Meter",
    "od_Sp_cons_SSB2 Meter",
    "od_Sp_cons_APFC-2 Meter",
  ],
  specificDeviceCategories: ["Enterprise"],
  specificParameterType: "Derived",
  specificTrendType: "Consumption",
  specificTrendRequired: true,
  specificUnit: "kWh/Unit",
  costTitle: "cost",
  costDeviceCategories: ["Total Meter"],
  costParameterCategories: ["Energy Cost"],
  costParameterType: "Derived",
  costUnit: "₹",
  costFormat: "en-IN",
};

// For Donut Chart
export const donutChartConfig = {
  chartConfig: [
    {
      Name: "DG Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DG Percentage",
      Color: "#EB5353",
    },
    {
      Name: "DISCOM",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DISCOM Percentage",
      Color: "#1C82AD",
    },
  ],
  chartHeight: "200px",
  chartCutOut: "60%",
  chartLabelOffSet: 40,
};

export const horizontalChartConfigurations = {
  chartTitle: "Energy Cost",
  requiredParameters: [
    {
      parameterCategories: ["Energy Cost OnDemand ToD1"],
      deviceCategories: ["ToD Meter"],
      name: "DISCOM",
      color: "#1C82AD",
      type: "Derived",
    },
    {
      parameterCategories: ["Energy Cost"],
      deviceCategories: ["DG Meter"],
      name: "DG",
      color: "#EB5353",
      type: "Derived",
    },
  ],
  legendPosition: "bottom",
  chartHeight: "230px",
  showYLabels: false,
};

// Daily Total Energy Consumption Chart
export const dailyConsumptionChartConfig = {
  title: "Daily Total Energy Consumption",
  chartConfig: [
    {
      displayName: "DISCOM",
      deviceCategories: ["ToD Meter"],
      parameterCategories: ["dailyActiveEnergy_Import ToD1"],
      color: "#1C82AD",
      apiType: "daily",
      type: "Derived",
    },
    {
      displayName: "DG",
      deviceCategories: ["DG Meter"],
      parameterCategories: ["dailyActiveEnergy"],
      color: "#EB5353",
      apiType: "daily",
      type: "Derived",
    },
  ],
  showLegends: true,
  yAxisTitle: "kWh",
  xAxisTitle: "",
  chartHeight: "180px",
  includeAllDays: true,
  xAxisFormat: "DD",
  unit: "kWh",
  operation: "sum",
  groupBy: "day",
  legendAlignment: "end",
  legendPosition: "top",
  showFooter: true,
};

// Monthly Consumption Chart

export const monthlyConsumptionChartConfig = {
  title: "Energy Consumption Monthly Trends",
  chartConfig: [
    {
      label: "DISCOM",
      deviceCategories: ["ToD Meter"],
      parameterCategories: ["dailyActiveEnergy_Import ToD1"],
      borderColor: "#1C82AD",
      backgroundColor: "#1C82AD",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
    {
      label: "DG",
      deviceCategories: ["DG Meter"],
      parameterCategories: ["dailyActiveEnergy"],
      borderColor: "#EB5353",
      backgroundColor: "#EB5353",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
    {
      label: "Total",
      deviceCategories: ["Total Meter"],
      parameterCategories: ["dailyActiveEnergy"],
      borderColor: "#03C988",
      backgroundColor: "#03C988",
      parameterType: "Derived",
      yAxisID: "Energy",
    },
  ],
  yAxisDetails: [
    {
      id: "Energy",
      position: "left",
      titleText: "Energy (in kWh)",
      titleAlign: "center",
    },
  ],
  operation: "sum",
  groupBy: "month",
  legendPosition: "top",
  includeAllDays: true,
  chartHeight: "250px",
  legendAlignment: "end",
};

// Daily Specific Energy Consumption Chart
export const specificConsumptionChartConfig = {
  title: "Daily Specific Energy Consumption",
  chartConfig: [
    {
      displayName: "DISCOM",
      deviceCategories: ["Enterprise"],
      parameterCategories: ["daily_DISCOM_Sp_cons_SikkimPlant"],
      color: "#1C82AD",
      apiType: "daily",
      type: "Derived",
    },
    {
      displayName: "DG",
      deviceCategories: ["Enterprise"],
      parameterCategories: ["daily_DG_Sp_cons_SikkimPlant"],
      color: "#EB5353",
      apiType: "daily",
      type: "Derived",
    },
  ],
  showLegends: true,
  yAxisTitle: "kWh/MT",
  xAxisTitle: "",
  chartHeight: "180px",
  includeAllDays: true,
  xAxisFormat: "DD",
  unit: "kWh/MT",
  operation: "sum",
  groupBy: "day",
  legendAlignment: "end",
  legendPosition: "top",
  showFooter: true,
};

// {
//   displayName: "Total",
//   deviceCategories: ["Enterprise"],
//   parameterCategories: ["daily_Sp_cons_SikkimPlant"],
//   color: "#1C82AD",
//   apiType: "daily",
//   type: "Derived",
// },

export const feederBreakUpConfig = {
  title: "Feeder BreakUp (kWh)",
  parameterCategories: ["ondemandActiveEnergy"],
  deviceCategories: ["Feeder Meter"],
  unit: "kWh",
};

// Box Plot Power Configurations
export const boxPlotPower = {
  title: "Power",
  deviceCategory: ["Feeder Meter"],
  parameterCategory: "Active Power",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "W",
  uniqueId: "box-plot-power",
  chartHeight: "140px",
};

// Box Plot PF Configurations
export const boxPlotPF = {
  title: "PF",
  deviceCategory: ["Feeder Meter"],
  parameterCategory: "Power Factor",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "",
  uniqueId: "box-plot-pf",
  chartHeight: "140px",
};

// Box Plot Current Configurations
export const boxPlotCurrent = {
  title: "Current",
  deviceCategory: ["Feeder Meter"],
  parameterCategory: "Current",
  legendPosition: "top",
  legendAlignment: "center",
  currentColor: "#8FE8ED",
  previousColor: "#C3C8D6",
  currentLabel: "Current",
  previousLabel: "Previous",
  unit: "A",
  uniqueId: "box-plot-current",
  chartHeight: "140px",
};

// Infoboard Configurations

export const infoBoardIntervalDuration = 60000; // Default duration is 60000

// For the energy consumption trend cards and cost
export const infoboardEnergyConsumptionDetails = {
  mainTitle: "Energy Consumption",
  totalTrendCardTitle: "Total",
  totalParameterCategories: ["ondemandActiveEnergy"],
  totalDeviceCategories: ["Total Meter"],
  totalParameterType: "Derived",
  totalTrendType: "Consumption",
  totalTrendRequired: true,
  totalUnit: "kWh",
  specificTrendCardTitle: "Specific",
  specificParameterCategories: ["ondemand_Sp_cons_SikkimPlant"],
  specificDeviceCategories: ["Enterprise"],
  specificParameterType: "Derived",
  specificTrendType: "Consumption",
  specificTrendRequired: true,
  specificUnit: "kWh/Unit",
  costTitle: "cost",
  costDeviceCategories: ["Total Meter"],
  costParameterCategories: ["Energy Cost"],
  costParameterType: "Derived",
  costUnit: "₹",
  costFormat: "en-IN",
};

// For Donut Chart
export const infoboardDonutChartConfig = {
  chartConfig: [
    {
      Name: "DG Meter",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DG Percentage",
      Color: "#EB5353",
    },
    {
      Name: "DISCOM",
      DeviceCategory: "Total Meter",
      ParameterCategory: "DISCOM Percentage",
      Color: "#1C82AD",
    },
  ],
  chartHeight: "172px",
  chartCutOut: "60%",
  chartLabelOffSet: 32,
};

// For Carbon FootPrint Widget
export const infoboardCarbonFootPrintConfig = {
  mainTitle: "Carbon Footprint",
  emissionTrendCardTitle: "Actual Emission",
  emissionParameterCategories: ["ondemand_Carbon_FP"],
  emissionDeviceCategories: ["Total Meter"],
  emissionParameterType: "Derived",
  emissionTrendType: "Consumption",
  emissionTrendRequired: false,
  emissionUnit: "kg CO2",
  savingsTrendCardTitle: "Carbon Savings",
  savingsParameterCategories: ["ondemand_CFP_Saving"],
  savingsDeviceCategories: ["Total Meter"],
  savingsParameterType: "Derived",
  savingsTrendType: "Production",
  savingsTrendRequired: true,
  savingsUnit: "kg CO2",
};

// Not used
// For Export Widget
export const infoboardExportConfig = {
  mainTitle: "Export",
  trendCardTitle: "Total",
  parameterCategories: ["ondemandActiveEnergy_Exp_total_kWH"],
  deviceCategories: ["ToD Meter"],
  parameterType: "Derived",
  trendType: "Consumption",
  trendRequired: true,
  unit: "kWh",
};

// For Energy Consumption Trend Chart
export const consumptionTrendChartConfig = {
  title: "Energy Consumption Trend",
  chartDetails: {
    parameterCategories: ["dailyActiveEnergy"],
    deviceCategories: ["Total Meter"],
    parameterType: "Derived",
    currentChartColor: "#86E5FF",
    currentDisplayName: "Current",
    currentBorderColor: "#86E5FF",
    previousDisplayName: "Previous",
    previousChartColor: "#FFC93C",
  },
  operation: "sum",
  yAxisTitle: "kWh",
  legendAlignment: "end",
  legendPosition: "top",
  chartType: "bar",
  showLegends: true,
  showOnlyCurrentData: false,
  showTarget: false,
  targetConfigurations: {},
  chartHeight: "175px",
  hideSecondXLabel: true,
};

// For Energy Consumption Cumulative Graph
export const cumulativeGraphConfig = {
  title: "Energy Consumption Cumulative Graph",
  parameterDetails: [
    {
      Name: "DISCOM",
      DeviceCategory: "Total Meter",
      ParameterCategory: "dailyActiveEnergy",
      Color: "red",
      ParameterType: "Derived",
    },
  ],
  chartDetails: {
    currGraphColor: "#1746A2",
    prevGraphColor: "#A4B7DE",
    targGraphColor: "#EB1D36",
  },
  operation: "sum",
  yAxisTitle: "kWh",
  xAxisTitle: "",
  chartHeight: "545px",
};

// Reports Configurations

// For Event Report
export const eventReportConfigurations = {
  buttonLabel: "Event Report",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  showSubmitButton: false,
  options: [
    { name: "Critical", id: "Critical", value: "Critical" },
    { name: "Informational", id: "Informational", value: "Informational" },
    { name: "Warning", id: "Warning", value: "Warning" },
  ],
  columnsToShow: [
    "EventName",
    "EventCategory",
    "Source",
    "OccuredOn",
    "CurrentStatus",
    "RemovedOn",
    "IsAcknowledged",
    "AcknowledgedBy",
    "AlertValue",
    "Comment",
  ],
  columnDetails: {
    EventName: {
      show: true,
      displayName: "Event Name",
    },
    EventCategory: {
      show: true,
      displayName: "Event Class",
    },
    Source: {
      show: true,
      displayName: "Source",
    },
    AlertValue: {
      show: true,
      displayName: "Alert Value",
    },
    OccuredOn: {
      show: true,
      displayName: "Occured On",
      isDateTime: true,
    },
    CurrentStatus: {
      show: true,
      displayName: "Status",
    },
    RemovedOn: {
      show: true,
      displayName: "Removed On",
      isDateTime: true,
    },
    IsAcknowledged: {
      show: true,
      displayName: "Ack Status",
      isBoolean: true,
    },
    AcknowledgedBy: {
      show: true,
      displayName: "Acked By",
    },
    Comment: {
      show: true,
      displayName: "Comment",
    },
  },
};

// For User Report
export const userReportConfigurations = {
  buttonLabel: "User Report",
  columnsOrder: [
    "Username",
    "RoleName",
    "AssignedNode",
    "CreateDate",
    "EditDate",
    "Status",
    "RemovedDate",
  ],
  columnDetails: {
    Username: {
      show: true,
      displayName: "User",
    },
    RoleName: {
      show: true,
      displayName: "Role",
    },
    AssignedNode: {
      show: true,
      displayName: "Assigned Node",
    },
    CreateDate: {
      show: true,
      displayName: "Created Date",
    },
    EditDate: {
      show: true,
      displayName: "Edited Date",
    },
    Status: {
      show: true,
      displayName: "Status",
    },
    RemovedDate: {
      show: true,
      displayName: "Removed Date",
    },
  },
};

// For Electrical Report
export const electricalReportConfigurations = {
  buttonLabel: "Electrical Report",
  hourlyButtonLabel: "Hourly Electrical Report",
  showSubmitButton: false,
  groupBy: "none",
  groupByForHourly: "hour",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  electricalColumnDateFormat: "DD-MM-YYYY hh:mm:ss A",
  dropDownOptions: [
    {
      columnTitle: "DISCOM Consumption",
      name: "DISCOM Consumption",
      api: "telemetry",
      summaryAPI: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy_Import  ToD1"],
      deviceCategories: ["ToD Meter", "Feeder Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "DG Consumption",
      name: "DG Consumption",
      api: "telemetry",
      summaryAPI: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy"],
      deviceCategories: ["DG Meter", "Feeder Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "DISCOM Current",
      name: "DISCOM Current",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["ToD Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "A",
    },
    {
      columnTitle: "DISCOM Power",
      name: "DISCOM Power",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["ToD Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: false,
    },
    {
      columnTitle: "DISCOM PF",
      name: "DISCOM PF",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["ToD Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "",
    },
    {
      columnTitle: "DG Current",
      name: "DG Current",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["DG Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "A",
    },
    {
      columnTitle: "DG Power",
      name: "DG Power",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["DG Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "DG PF",
      name: "DG PF",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["DG Meter", "Feeder Meter"],
      parameterType: "Basic",
      unit: "",
    },
  ],
  dropDownOptionsForFeeder: [
    {
      columnTitle: "Consumption",
      name: "Consumption",
      api: "telemetry",
      summaryAPI: "aggregation-derived",
      aggregation: "sum",
      parameterCategories: ["deltaActiveEnergy"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
    {
      columnTitle: "Current",
      name: "Current",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "A",
    },
    {
      columnTitle: "Power",
      name: "Power",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "PF",
      name: "PF",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "",
    },
  ],
};

// For Cost Report
export const costReportConfigurations = {
  buttonLabel: "Daily Energy Cost Report",
  showSubmitButton: false,
  groupBy: "day",
  includeAllDays: true,
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "DD-MM-YYYY",
  dropDownOptions: [
    {
      name: "DISCOM Cost",
      columnTitle: "DISCOM Cost",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Energy Cost Daily ToD1"],
      deviceCategories: ["ToD Meter"],
      parameterType: "Derived",
      unit: "INR",
    },
    {
      name: "DG Cost",
      columnTitle: "DG Cost",
      api: "daily",
      summaryAPI: "aggregation-derived-daily",
      aggregation: "sum",
      parameterCategories: ["Daily Energy Cost"],
      deviceCategories: ["DG Meter"],
      parameterType: "Derived",
      unit: "INR",
    },
  ],
  includeAllDays: true,
};

// For Feeder Report
export const feederReportConfigurations = {
  showSubmitButton: false,
  buttonLabel: "Feeder Level Energy Report",
  reportTitle: "Feeder Level Energy Report",
  groupBy: "none",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  columnDateFormat: "DD-MM-YYYY hh:mm:ss A",
  dropDownOptions: [
    {
      columnTitle: "Power",
      name: "Power",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "kW",
      divideByThousand: true,
    },
    {
      columnTitle: "Current",
      name: "Current",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "Amps",
    },
    {
      columnTitle: "Power Factor",
      name: "Power Factor",
      api: "telemetry",
      summaryAPI: "aggregation-basic",
      aggregation: "avg",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      unit: "",
    },
    {
      columnTitle: "kWh Cumulative",
      name: "kWh Cumulative",
      api: "cumulative",
      summaryAPI: "cumulative",
      aggregation: "avg",
      parameterCategories: ["Total_kWH_R"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Derived",
      unit: "kWh",
    },
  ],
};

// Not used
// For KSEB Bill Report
export const ksebBillConfigurations = {
  reportTitle: "KSEB Bill Cost Components",
  buttonLabel: "KSEB Bill Cost Components",
  derivedParameters: {
    "zone-1-consumption": ["bill_monthly_Imp_ToD1_kWH"],
    "zone-2-consumption": ["bill_monthly_Imp_ToD2_kWH"],
    "zone-3-consumption": ["bill_monthly_Imp_ToD3_kWH"],
    "total-consumption": ["bill_monthly_Imp_Total_kWH"],
    "zone-1-amount": ["bill_monthly_imp_ToD1_Cost"],
    "zone-2-amount": ["bill_monthly_imp_ToD2_Cost"],
    "zone-3-amount": ["bill_monthly_imp_ToD3_Cost"],
    "total-amount": ["bill_monthly_imp_Total_Cost"],
    "electric-duty-amount": ["Electricity_Duty"],
    "electric-surcharge-amount": ["Electricity_surcharge"],
    "fuel-surcharge-amount": ["Fuel_surcharge"],
    "monthly-fuel-surcharge-amount": ["Monthly_fuel_surcharge"],
    "duty-charge-total-amount": ["Duty_charges_Total"],
    "self-generated-duty": ["Self_gen_duty"],
    "monthly-md-total-amount": ["monthly_MD_charge_normal"],
    "total-charges": ["Net_Monthly_charge"],
    "demand-kva-zone1": ["monthlyMD_Z1"],
    "demand-kva-zone2": ["monthlyMD_Z2"],
    "demand-kva-zone3": ["monthlyMD_Z3"],
    "demand-kva-normal": ["monthly_MD"],
    "demand-kva-charge-normal": ["monthly_MD_charge_normal"],
  },
  deviceCategories: ["ToD Meter"],
  constantParameters: {
    "zone-1-rate": "Virtual AVT ToD Meter_ToD1_Unit cost",
    "zone-2-rate": "Virtual AVT ToD Meter_ToD2_Unit cost",
    "zone-3-rate": "Virtual AVT ToD Meter_ToD3_Unit cost",
    "electric-duty-rate": "Virtual AVT ToD Meter_Electricity_Duty_Mult",
    "electric-surcharge-rate": "Virtual AVT ToD Meter_Elect_sur_Mult",
    "fuel-surcharge-rate": "Virtual AVT ToD Meter_Fuel_sur_Mult",
    "monthly-fuel-surcharge-rate": "Virtual AVT ToD Meter_Month_fuel_sur_Mult",
    "self-generated-duty-consumption":
      "Virtual AVT ToD Meter_Self_gen_duty_KWh",
    "self-generated-duty-rate":
      "Virtual AVT ToD Meter_Self_gen_duty_multiplier",
    "belated-payment-charges": "Virtual AVT ToD Meter_Belated_payment",
    "pf-incentive-consumption": "Virtual AVT ToD Meter_bill_monthlyPF",
    "pf-incentive-amount": "Virtual AVT ToD Meter_PF_Incentive",
  },
  realTimeParameters: {
    eou: {
      deviceCategories: ["EoU ToD Meter"],
      parameterCategories: {
        "zone-1-energy": ["Import Active Energy ToD1 RealTime"],
        "zone-2-energy": ["Import Active Energy ToD2 RealTime"],
        "zone-3-energy": ["Import Active Energy ToD3 RealTime"],
      },
      type: "Basic",
    },
    domestic: {
      deviceCategories: ["Domestic ToD Meter"],
      parameterCategories: {
        "zone-1-energy": ["Import Active Energy ToD1 RealTime"],
        "zone-2-energy": ["Import Active Energy ToD2 RealTime"],
        "zone-3-energy": ["Import Active Energy ToD3 RealTime"],
      },
      type: "Basic",
    },
  },
};

// For Summary Report
export const summaryReportConfigurations = {
  reportTitle: "Energy Consumption Summary Report",
  buttonLabel: "Energy Consumption Summary Report",
  dateTimeFormat: "DD-MM-YYYY hh:mm:ss A",
  dropDownOptions: [
    {
      columnTitle: "Power",
      name: "Power",
      parameterCategories: ["Active Power"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      api: "aggregation-basic",
      aggregation: "avg",
      divideByThousand: true,
      unit: "kW",
    },
    {
      columnTitle: "Current",
      name: "Current",
      parameterCategories: ["Current"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Basic",
      aggregation: "avg",
      api: "aggregation-basic",
      unit: "A",
    },
    {
      columnTitle: "Power Factor",
      name: "Power Factor",
      parameterCategories: ["Power Factor"],
      deviceCategories: ["Feeder Meter"],
      api: "aggregation-basic",
      aggregation: "avg",
      parameterType: "Basic",
    },
    {
      columnTitle: "kWh Cumulative",
      name: "kWh Cumulative",
      parameterCategories: ["Total_kWH_R"],
      deviceCategories: ["Feeder Meter"],
      parameterType: "Derived",
      api: "cumulative",
      unit: "kWh",
    },
  ],
  summaryOptions: [
    {
      columnTitle: "DG Cost",
      parameterCategories: ["Energy Cost"],
      deviceCategories: ["DG Meter"],
      unit: "INR",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DISCOM Cost",
      parameterCategories: ["Energy Cost OnDemand ToD1"],
      deviceCategories: ["ToD Meter"],
      unit: "INR",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "Total Cost",
      parameterCategories: ["Energy Cost"],
      deviceCategories: ["Total Meter"],
      unit: "INR",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "Total Specific Consumption",
      parameterCategories: ["ondemand_Sp_cons_SikkimPlant"],
      deviceCategories: ["Enterprise"],
      unit: "kWh",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DISCOM Avg Unit Cost",
      parameterCategories: ["ondemand Unit Cost DISCOM"],
      deviceCategories: ["ToD Meter"],
      unit: "INR",
      api: "evaluator",
      parameterType: "Derived",
    },
    {
      columnTitle: "DG Avg Unit Cost",
      parameterCategories: ["Ondemand_DG_Unit_cost"],
      deviceCategories: ["DG Meter"],
      unit: "INR",
      api: "evaluator",
      parameterType: "Derived",
    },
  ],
  excludeStartTime: false,
  showChildNodeSummary: false,
  groupBy: "none",
};

// Configurations for Events Page

export const eventsPageConfigurations = {
  eventTableColumns: {
    alertname: "Alert",
    nodeName: "Source",
    category: "Category",
    alertvalue: "Alert Value",
    currentstatus: "Status",
    createdDate: "Occured On",
    acknowledgedDate: "Acked On",
    acknowledgedBy: "Acked By",
    isAcknowledged: "Ack Status",
    action: "Action",
    ignore: "Disable",
  },
  columnsToFilter: {
    category: ["Critical", "Warning", "Informational"],
    nodeName: [],
    isAcknowledged: ["YES", "NO"],
  },
  columnsToSort: ["alertname", "category", "createdDate", "acknowledgedDate"],
};

// Configurations for Analytics Page

export const parameterMatchesForAnalytics = [
  "deltaActiveEnergy",
  "dailyActiveEnergy",
  "monthlyActiveEnergy",
  "ondemandActiveEnergy",
  "Frequency",
  "Power Factor",
  "Current",
  "Voltage",
  "Apparent Power",
  "Active Power",
  "Apparent Energy",
  "Active Energy",
  "Power Demand",
];
