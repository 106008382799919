<script>
  import moment from "moment";
  import Icon from "@iconify/svelte";
  import * as XLSX from "xlsx";
  import Loader from "./../Loader/Loader.svelte";

  export let baseURL;
  export let parentNodeID;
  export let currentScreen = "Plan Input";
  export let startTime;
  export let endTime;
  export let deviceCategory;
  export let parameterCategory;
  export let offsetTime;
  export let isWritePermission = false;
  export let parametersToDisable = [];

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  let isGetExternalParamatersAPIAwaiting = false;

  let getExternalParametersPayload = {
    NodeId: parentNodeID,
    ParameterCategory: parameterCategory,
    DeviceCategory: deviceCategory,
  };

  let getDerivedParametersPayload = {};

  let getPlanInputPayload = {};

  let editPlanInputPayload = [
    {
      ID: "",
      ParameterID: "",
      Timestamp: "",
      Reading: "",
    },
  ];

  let deletePlanInputPayload = [
    {
      parameterid: "",
      timestamp: "",
    },
  ];

  let savePlanInputPayload = [
    {
      parameterid: "",
      timestamp: "",
      reading: "",
    },
  ];

  let dataAwaiting = "Ready";
  let screenName = "PlanInput";
  let externalParameters = [];
  let derivedParameters = [];
  let planInputData = [];
  let savedData = [];
  let deletedData = [];
  let editedData = [];
  let tableHeading = [];
  let dataStored = {};
  let calculationHeading = [];
  let calculatedData = {};
  let derivedParameterList = [];
  let dateArray = [];

  $: {
    if (startTime && endTime) {
      getDaysArray(startTime, endTime);
      getDerivedParameters();
      getPlanInput();
    }
  }

  $: {
    if (baseURL && deviceCategory && parameterCategory && parentNodeID) {
      getExternalParameters();
    }
  }

  async function getExternalParameters() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };

    try {
      const url = `${baseURL}/parameters/api/external/all`;

      getExternalParametersPayload = {
        NodeId: parentNodeID,
        ParameterCategory: parameterCategory,
        DeviceCategory: deviceCategory,
      };

      if (
        !(
          getExternalParametersPayload &&
          getExternalParametersPayload.DeviceCategory &&
          getExternalParametersPayload.ParameterCategory &&
          getExternalParametersPayload.NodeId
        )
      )
        return;

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(getExternalParametersPayload),
      };

      isGetExternalParamatersAPIAwaiting = true;
      const response = await fetch(url, options);
      isGetExternalParamatersAPIAwaiting = false;
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      externalParameters = await response.json();
      getExternalParametersOnSuccess(externalParameters);
      getDerivedParameters();
    } catch (error) {
      isGetExternalParamatersAPIAwaiting = false;
      console.error(error);
    }
  }

  async function getDerivedParameters() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };

    if (!(startTime && endTime && derivedParameterList?.length)) return;

    try {
      const url = `${baseURL}/derivedparameter/api/daily`;
      getDerivedParametersPayload = {
        EndTime: endTime,
        StartTime: startTime,
        Parameters: derivedParameterList,
      };

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(getDerivedParametersPayload),
      };

      if (
        !(
          getDerivedParametersPayload &&
          getDerivedParametersPayload.EndTime &&
          getDerivedParametersPayload.StartTime &&
          getDerivedParametersPayload.Parameters
        )
      )
        return;

      const response = await fetch(url, options);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      derivedParameters = await response.json();
      getDerivedParametersOnSuccess();
    } catch (error) {
      console.error(error);
    }
  }

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return "";
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : parseInt(value);
  };

  function getDerivedParametersOnSuccess() {
    let currentData = { ...calculatedData };
    derivedParameters.forEach((item) => {
      item.Readings.forEach((reading) => {
        const timeWithoutOffset = reading.Timestamp - 86400;
        const key =
          item.Derived_Parameter_Id +
          moment.unix(timeWithoutOffset).format("DD MMM YYYY");
        const value = convertToTwoDigits(reading.Reading);
        currentData[key] = value;
      });
    });
    calculatedData = { ...currentData };
  }

  async function getPlanInput() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };

    try {
      getPlanInputPayload = {
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
      };
      const url = `${baseURL}/parameters/api/external/getplaninput`;
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(getPlanInputPayload),
      };
      if (
        !(
          getPlanInputPayload &&
          getPlanInputPayload.startTime &&
          getPlanInputPayload.endTime
        )
      ) {
        return;
      }
      const response = await fetch(url, options);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      planInputData = await response.json();
      getPlanInputOnSuccess(planInputData);
    } catch (error) {
      console.error(error);
    }
  }

  function getPlanInputOnSuccess(data) {
    let currentData = { ...dataStored };
    data.forEach((entry) => {
      const timeWithoutOffset = entry.Timestamp - 86400;
      const key =
        entry.ParameterID +
        moment.unix(timeWithoutOffset).format("DD MMM YYYY");
      const value = entry.Reading;
      currentData[key] = value;
    });
    dataStored = { ...currentData };
  }

  async function savePlanInput() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/W`,
    };

    try {
      const url = `${baseURL}/parameters/api/external/saveplaninput`;
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(savePlanInputPayload),
      };

      if (
        !(
          savePlanInputPayload[0] &&
          savePlanInputPayload[0].parameterid &&
          savePlanInputPayload[0].timestamp &&
          savePlanInputPayload[0].reading
        )
      )
        return;
      dataAwaiting = "Updating Values...";
      const response = await fetch(url, options);
      dataAwaiting = "Ready";
      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      savedData = await response.json();
      savePlanInputOnSuccess();
    } catch (error) {
      console.error(error);
    }
  }

  function savePlanInputOnSuccess() {
    const externalParameter = savedData["External Parameters"][0];

    const timeWithoutOffset = externalParameter.timestamp - 86400;
    const key =
      externalParameter.parameterid +
      moment.unix(timeWithoutOffset).format("DD MMM YYYY");

    const value = externalParameter.reading;
    dataStored[key] = value;
    const derivedParameter = savedData["Derived Parameters"];
    derivedParameter.forEach((item) => {
      const timeWithoutOffset = item.timestamp - 86400;
      const key =
        item.derived_parameter_id +
        moment.unix(timeWithoutOffset).format("DD MMM YYYY");

      const value = item.value !== "NaN" ? item.value : "";
      calculatedData[key] = value;
    });
  }

  async function deletePlanInput() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/W`,
    };

    try {
      const url = `${baseURL}/parameters/api/external/delete`;
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(deletePlanInputPayload),
      };

      dataAwaiting = "Updating Values...";
      const response = await fetch(url, options);
      dataAwaiting = "Ready";

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      deletedData = await response.json();
      updateStoredData(deletePlanInputPayload);
      updateCalculatedData(deletedData);
    } catch (error) {
      console.error(error);
    }
  }

  async function editPlanInput() {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/W`,
    };

    try {
      const url = `${baseURL}/parameters/api/external/editplaninput`;
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editPlanInputPayload),
      };

      dataAwaiting = "Updating Values...";
      const response = await fetch(url, options);
      dataAwaiting = "Ready";

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      editedData = await response.json();
      updateStoredData(editPlanInputPayload);
      updateCalculatedData(editedData);
    } catch (error) {
      console.error(error);
    }
  }

  function getExternalParametersOnSuccess(data) {
    let headings = [];
    let calcHeadings = [];
    let parameterObject = {};

    data["ExternalParameters"].forEach((item) => {
      parameterObject = {
        ExternalParameterName: item.ExternalParameterName,
        ExternalParameterID: item.ExternalParameterID,
        ParameterCategory: item?.ParameterCategory,
      };
      headings.push(parameterObject);
    });

    data["DerivedParameters"].forEach((item) => {
      parameterObject = {
        Derived_Parameter_Id: item.Derived_Parameter_Id,
        Derived_Parameter_Name: item.Derived_Parameter_Name,
      };
      calcHeadings.push(parameterObject);
      derivedParameterList.push(item.Derived_Parameter_Id);
    });
    tableHeading = headings;
    calculationHeading = calcHeadings;
    getDaysArray(startTime, endTime);
  }

  function getDaysArray(start, end) {
    let dateList = [];
    for (
      let dt = moment.unix(start);
      dt < moment.unix(end);
      dt.add(1, "days")
    ) {
      let date = moment.unix(dt / 1000).format("DD MMM YYYY");
      dateList.push(date);
    }
    // dateList.pop();
    dateArray = dateList;
  }

  function handleChange(data, value, time) {
    let parsedDate = moment(time, "DD MMM YYYY");
    let epochInSeconds = parsedDate.unix();
    let epochWithOffset = 86400 + epochInSeconds + offsetTime / 1000;
    if (dataStored[data.ExternalParameterID + time]) {
      if (value) handleEdit(data, value, epochWithOffset);
      else handleDelete(data, epochWithOffset);
    } else {
      handleSave(data, value, epochWithOffset);
    }
  }

  function handleSave(data, value, time) {
    savePlanInputPayload = [
      {
        parameterid: data.ExternalParameterID,
        timestamp: JSON.stringify(time),
        reading: value,
      },
    ];
    savePlanInput();
  }

  function handleEdit(parameter, reading, time) {
    let editedItem;
    for (let i = 0; i < planInputData.length; i++) {
      const entry = planInputData[i];
      if (
        entry.ParameterID === parameter.ExternalParameterID &&
        JSON.parse(entry.Timestamp) === time
      ) {
        editedItem = entry;
      }
    }
    editPlanInputPayload = [
      {
        ID: editedItem.ID,
        ParameterID: parameter.ExternalParameterID,
        Timestamp: JSON.stringify(time),
        Reading: reading,
      },
    ];
    editPlanInput();
  }

  function handleDelete(parameter, time) {
    deletePlanInputPayload = [
      {
        parameterid: parameter.ExternalParameterID,
        timestamp: JSON.stringify(time),
      },
    ];
    deletePlanInput();
  }

  function updateStoredData(item) {
    let currentData = { ...dataStored };
    if (item[0].Reading)
      currentData[
        item[0].ParameterID +
          moment.unix(item[0].Timestamp - 86400).format("DD MMM YYYY")
      ] = item[0].Reading;
    else
      currentData[
        item[0].parameterid +
          moment.unix(item[0].timestamp - 86400).format("DD MMM YYYY")
      ] = "";
    dataStored = { ...currentData };
  }

  function updateCalculatedData(newData) {
    let currentData = { ...calculatedData };
    newData.forEach((item) => {
      const timeWithoutOffset = item.timestamp - 86400;
      const key =
        item.derived_parameter_id +
        moment.unix(timeWithoutOffset).format("DD MMM YYYY");
      const value = item.value !== "NaN" ? item.value : "";
      currentData[key] = value;
    });
    calculatedData = { ...currentData };
  }

  function renderInputPage() {
    let headings = [];

    if (externalParameters["Processes"]) {
      externalParameters["Processes"].forEach((process) => {
        headings.push(`${process.ProcessName}`);
        process.ExternalParameters.forEach((parameter) => {
          headings.push(``);
        });
        headings.pop();
      });
    }

    if (externalParameters["ExternalParameters"]) {
      externalParameters["ExternalParameters"].forEach((parameter) => {
        headings.push(parameter.ExternalParameterName);
      });
    }
    let headerArray = [["Date"].concat(headings)];
    getDaysArray(startTime, endTime);
    const datesList = dateArray;

    let dataList = [];
    let tableData = [];
    datesList.forEach((date) => {
      tableHeading.forEach((heading) => {
        if (heading["ExternalParameters"]) {
          heading["ExternalParameters"].forEach((ext) => {
            if (dataStored[ext.ExternalParameterID + date])
              dataList.push(dataStored[ext.ExternalParameterID + date]);
            else dataList.push("");
          });
        } else {
          if (dataStored[heading.ExternalParameterID + date])
            dataList.push(dataStored[heading.ExternalParameterID + date]);
          else dataList.push("");
        }
      });

      tableData.push([date].concat(dataList));
      dataList = [];
    });
    return headerArray.concat(tableData);
  }

  function renderCalculationPage() {
    let headings = [];

    calculationHeading.forEach((item) => {
      headings.push(item.Derived_Parameter_Name);
    });

    let headerArray = [["Date"].concat(headings)];

    getDaysArray(startTime, endTime);
    const datesList = dateArray;

    let dataList = [];
    let tableData = [];

    datesList.forEach((date) => {
      calculationHeading.forEach((heading) => {
        derivedParameters.forEach((der) => {
          der["Readings"].forEach((reading) => {
            if (
              heading.Derived_Parameter_Id === der.Derived_Parameter_Id &&
              reading.Timestamp ===
                moment(date, "DD MMM YYYY").unix() + 86400 + offsetTime / 1000
            )
              if (reading.Reading !== "NaN") dataList.push(reading.Reading);
              else dataList.push("");
          });
        });
      });
      tableData.push([date].concat(dataList));
      dataList = [];
    });

    return headerArray.concat(tableData);
  }

  function exportData() {
    var workbook = XLSX.utils.book_new();

    let tableLeft = renderInputPage();
    let tableRight = renderCalculationPage();

    var worksheet = XLSX.utils.aoa_to_sheet(tableLeft);
    var worksheet2 = XLSX.utils.aoa_to_sheet(tableRight);
    const fileName = `Plan Input Export ${moment
      .unix(startTime)
      .format("DD MMM YYYY")} - ${moment
      .unix(endTime - 86400)
      .format("DD MMM YYYY")}.xls`;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Plan Input");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Calculations");

    XLSX.writeFile(workbook, fileName);
  }

  const isDisabled = function (column) {
    for (const item of parametersToDisable) {
      if (column?.ParameterCategory?.includes(item)) {
        return true;
      }
    }
    return false;
  };
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="plan-input-main-container">
  {#if isGetExternalParamatersAPIAwaiting}
    <Loader></Loader>
  {/if}
  <div class="header-btn-div">
    <button
      class="del-import-export-button"
      on:click={() => {
        exportData();
      }}
    >
      Export <Icon
        icon="ion:download-outline"
        color="#f26722"
        width="20"
        height="20"
      /></button
    >
  </div>
  <div class="tab-div">
    <button
      class={`del-inputscreen-tab-button ${
        screenName === "PlanInput" ? "selected" : ""
      }`}
      on:click={() => {
        screenName = "PlanInput";
      }}
    >
      Plan Input
    </button>
    <button
      class={`del-inputscreen-tab-button ${
        screenName === "Calculations" ? "selected" : ""
      }`}
      on:click={() => {
        screenName = "Calculations";
      }}
    >
      Calculations
    </button>
    <div
      class={`del-awaiting-label ${
        dataAwaiting === "Updating Values..." ? "update" : ""
      }`}
    >
      {dataAwaiting}
    </div>
  </div>
  <div class="table-container">
    <div class="table-div">
      {#if screenName === "PlanInput"}
        <table class="input-table">
          <thead>
            <tr class="input-title-row">
              <th class="sticky-class date-title sticky-header">Date</th>
              {#each tableHeading as title}
                <th class="input-process-title sticky-header">
                  {title["ExternalParameterName"]}
                </th>
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each dateArray as date}
              <tr class="input-row">
                <td class="sticky-class date-list">{date}</td>
                {#each tableHeading as heading}
                  <td>
                    <input
                      class="field-input"
                      id={heading.ExternalParameterID + date}
                      type="number"
                      step="any"
                      value={dataStored[heading.ExternalParameterID + date]}
                      on:change={(e) => {
                        handleChange(heading, e.target.value, date);
                      }}
                      disabled={!isWritePermission || isDisabled(heading)}
                    />
                  </td>
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {:else if screenName === "Calculations"}
        <table class="calculation-table">
          <thead>
            <tr class="calc-date-title">
              <th class="calc-date-title-cell">Date</th>
              {#each calculationHeading as heading}
                <th>{heading.Derived_Parameter_Name}</th>
              {/each}
            </tr>
          </thead>
          <tbody>
            {#each dateArray as date}
              <tr>
                <td class="date-list">{date}</td>
                {#each calculationHeading as heading}
                  <td class="field-output"
                    >{calculatedData[heading.Derived_Parameter_Id + date]
                      ? convertToTwoDigits(
                          calculatedData[heading.Derived_Parameter_Id + date]
                        )
                      : ""}</td
                  >
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    </div>
  </div>
</div>

<style>
  .plan-input-main-container {
    position: relative;
  }
  .table-div {
    overflow-x: auto;
    max-height: 550px;
    overflow-y: auto;
  }
  th.sticky-header {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0;
  }

  .input-table th.sticky-class:first-child {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    left: 0;
    z-index: 5;
    opacity: 1;
  }

  .input-table {
    width: 100%;
  }

  .calculation-table {
    width: 100%;
  }

  .input-table th.sticky-class:first-child.date-title {
    z-index: 6;
  }

  .input-table td.sticky-class:first-child {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    left: 0;
    z-index: 5;
    opacity: 1;
    background-color: white;
    border-left: 1px solid #ccd3e7;
  }

  .input-row {
    border-bottom: 1px solid #ccd3e7;
  }

  .input-title-row {
    height: 100px;
  }

  .date-title {
    background-color: #a0b8c1;
    color: white;
    font: normal normal 14px/19px Roboto;
    font-weight: bold;
  }

  .calc-date-title {
    background-color: #a0b8c1;
    color: white;
    font: normal normal 14px/19px Roboto;
    font-weight: bold;
    height: 100px;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0;
  }

  .calc-date-title-cell {
    max-width: 136px;
  }

  .date-list {
    text-align: center;
    font: normal normal 14px Roboto;
    height: 50px;
    font-weight: 600;
    border-left: 1px solid #ccd3e7;
    width: 178px;
  }

  .input-process-title {
    background-color: #16558f;
    color: white;
    font: normal normal 14px/19px Roboto;
    font-weight: bold;
    height: 50px;
  }

  .field-input {
    border: none;
    background-color: white;
    height: 50px;
    font: normal normal 14px Roboto;
    font-weight: 600;
    text-align: center;
  }

  .field-output {
    border-right: 1px solid #ccd3e7;
    border-bottom: 1px solid #ccd3e7;
    background-color: white;
    height: 50px;
    font: normal normal 14px/19px Roboto;
    font-weight: 600;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none;
  }

  .header-btn-div {
    display: flex;
    justify-content: end;
    position: relative;
  }

  .tab-div {
    display: flex;
    gap: 15px;
    padding: 5px;
  }

  td {
    border-right: 1px solid #ccd3e7;
    border-bottom: 1px solid #ccd3e7;
    text-align: center;
  }

  .del-inputscreen-tab-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-inputscreen-tab-button.selected {
    background-color: #16558f;
    border: 1px solid #16558f;
    color: white;
  }

  .del-import-export-button {
    cursor: pointer;
    border: none;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 5px;
    transition: all 0.5s;
  }

  .del-awaiting-label {
    margin-left: auto;
    text-align: left;
    font: normal normal 14px/19px Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #1b9e58;
    opacity: 1;
    padding: 5px;
  }

  .del-awaiting-label.update {
    color: rgb(228, 213, 2);
  }
</style>
