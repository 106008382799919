<script>
  import DelEventTable from "../components/DelEventTable/DelEventTable.svelte";
  import {
    selectedNode,
    defaultNodeId,
    currentPage,
    availableScreens,
  } from "./../stores";
  import { screensToShow, eventsPageConfigurations } from "./../../config.js";
  import { getDomainURL } from "./../invokeAPI";
  import { onMount } from "svelte";

  $: selectedNodeId = $selectedNode?.id;

  const urlParams = new URLSearchParams(window.location.search);
  const nodeIdFromURL = urlParams.get("nodeId");

  onMount(() => {
    sessionStorage.removeItem("application-initial-load");
  });

  if (nodeIdFromURL) {
    defaultNodeId.set(nodeIdFromURL);
  }

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);
</script>

<div class="del-event-viewer-container">
  <DelEventTable
    currentNodeId={selectedNodeId}
    selectedNodeDetails={$selectedNode}
    baseURL={getDomainURL()}
    tableHeadings={eventsPageConfigurations?.eventTableColumns}
    isWritePermission={screenPermission === "RW"}
    filterColumns={eventsPageConfigurations?.columnsToFilter}
    sortColumns={eventsPageConfigurations?.columnsToSort}
  />
</div>

<style>
  .del-event-viewer-container {
    padding: 32px 28px;
  }
</style>
